import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState, useContext } from "react";
import { FaChartBar, FaHome, FaSchool } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authentication, firestore } from "../firebase";
import { UserContext } from "../realm/user.context";
import { loadDistrict, loadDistrictFromCleverId, loadProfile } from "../realm/graphqlQueries";
import "./Sidebar.css";
import { Context2 } from "./Audio";


const Sidebar = (props) => {
  const [user, setUser] = useState("student");
  const [school, setSchool] = useState("");
  const [district, setDistrict] = useState("");
  const { realmUser, logInRealmUser, logOutRealmUser } =
    useContext(UserContext);
  const [playing, setPlaying] = useContext(Context2);
  const navigate = useNavigate();

  const location = useLocation();

  const [isActive, setIsActive] = useState(false);
    const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  async function changeAudio(audio) {
    document.cookie = `audio=${audio}; path=/;`;
    if (location.pathname === "/deepbreathe") {
      navigate("/deepbreathecopy");
    } else {
      navigate(location.pathname);
    }
    setPlaying(audio);
  }

  async function getUserData() {
    const idToken = await user.getIdToken(/* forceRefresh */ true);
    let realmResp = null;
    if (realmUser === null) {
      const authedUser = await logInRealmUser(idToken);
      realmResp = await loadProfile(
        authentication.currentUser.email,
        authedUser
      );
    } else {
      realmResp = await loadProfile(
        authentication.currentUser.email,
        realmUser
      );
    }
    // console.log(realmResp);
    if (realmResp.user.school) setSchool(realmResp.user.school);
    if (realmResp.user.district) setDistrict(realmResp.user.district);
    setUser(realmResp.user.user);
  }

  const getDistrictName = async () => {
    console.log(props.user.user.district);
    let realmResp;
     realmResp = await loadDistrictFromCleverId(
      props.user.user.district,
      realmUser
    );
    if (realmResp.district == null){
      realmResp = await loadDistrict(props.user.user.district,
      realmUser)
    }
    console.log(realmResp)
    return realmResp.district != null ? realmResp.district.name : "";
  };

  useEffect(() => {
    if (props.user.user.school) {
      setSchool(props.user.user.school);
    }
    if (props.user.user.district) {
      if (props.user.user.cleverId) {
        getDistrictName().then((name) => {
          setDistrict(name);
        });
      } else {
        getDistrictName()
          .then((name) => setDistrict(name))
          .catch(() => setDistrict(props.user.user.district));
      }
    }
    if (props.user.user.user) {
      setUser(props.user.user.user);
    }
    //getUserData();
  }, []);
  return (
    <>
    <div className="ham">
      <div>
          <img
                className="subtab-logo"
                src="images/123WellnessLogo green-02.png"
                alt="1-2-3 Wellness"
                loading="lazy"
            />
      </div>
      <div className={`ham-menu ${isActive ? 'active' : ''}`} 
          onClick={toggleSidebar}>
        <span></span>
        <span></span>
        <span></span>
        
      </div>
    </div>
    <div className="sidebar" >
      <div className={`sidebarcolumn ${isActive ? 'active' : ''}`} onClick={toggleSidebar}>
        <div className="sidebarrow">
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              className="sidebarlogo"
              src="images/123WellnessLogo green-02.png"
              alt="1-2-3 Wellness"
              loading="lazy"
            />
          </Link>
          {user === "student" && (
            <div className="alignMenuOptions">
              <Link to="/" className={location.pathname === "/" ? "active" : ""}>
                <div className="icontextrowstudent">
                  <div className="styleiconstudenthome">
                    <FaHome></FaHome>
                  </div>
                  <h1 className="icontexthome">Home</h1>
                </div>
              </Link>
              <Link
                to="/profilescreen"
                className={
                  location.pathname === "/profilescreen" ? "active" : ""
                }
              >
                <div className="icontextrowstudent">
                  <div className="styleiconstudent">
                    <FaUser></FaUser>
                  </div>
                  <h1 className="icontext">My Story</h1>
                </div>
              </Link>
              {playing === true ? (
                <div className="icontextrowstudent" onClick={() => changeAudio(false)}>
                  <div className="styleiconstudent">
                    <FaVolumeUp></FaVolumeUp>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              ) : (
                <div className="icontextrowstudent" onClick={() => changeAudio(true)}>
                  <div className="styleiconstudent">
                    <FaVolumeMute></FaVolumeMute>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              )}
              <Link
                to="/optionsscreen"
                className={
                  location.pathname === "/optionsscreen" ? "active" : ""
                }
              >
                <div className="icontextrowstudent">
                  <div className="styleiconstudent">
                    <FaCog></FaCog>
                  </div>
                  <h1 className="icontext">Settings</h1>
                </div>
              </Link>
            </div>
          )}
          {user === "teacher" && (
            <div className="alignMenuOptions">
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                <div className="icontextrowteacher">
                  <div className="styleiconteacherhome">
                    <FaHome></FaHome>
                  </div>
                  <h1
                    className="icontexthome"
                  >
                    Home
                  </h1>
                </div>
              </Link>
              <Link
                to="/profilescreen"
                className={
                  location.pathname === "/profilescreen" ? "active" : ""
                }
              >
                <div className="icontextrowteacher">
                  <div className="styleiconteacher">
                    <FaUser></FaUser>
                  </div>
                  <h1
                    className="icontext"
                  >
                    My Story
                  </h1>
                </div>
              </Link>
              <Link
                to="/viewclassdata"
                className={
                  location.pathname === "/viewclassdata" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaChartBar></FaChartBar>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Student Data
                  </h1>
                </div>
              </Link>
              <Link
                to="/classscreen"
                className={location.pathname === "/classscreen" ? "active" : ""}
              >
                <div className="icontextrowteacher">
                  <div className="styleiconteacher">
                    <FaBook></FaBook>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Setup Classes
                  </h1>
                </div>
              </Link>
              {playing === true ? (
                <div className="icontextrowteacher" onClick={() => changeAudio(false)}>
                  <div className="styleiconteacher">
                    <FaVolumeUp></FaVolumeUp>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              ) : (
                <div className="icontextrowteacher" onClick={() => changeAudio(true)}>
                  <div className="styleiconteacher">
                    <FaVolumeMute></FaVolumeMute>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              )}
              <Link
                to="/optionsscreen"
                className={
                  location.pathname === "/optionsscreen" ? "active" : ""
                }
              >
                <div className="icontextrowteacher">
                  <div className="styleiconteacher">
                    <FaCog></FaCog>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Settings
                  </h1>
                </div>
              </Link>
            </div>
          )}
          {user === "admin" && (
            <div className="alignMenuOptions">
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadminhome">
                    <FaHome></FaHome>
                  </div>
                  <h1
                    className="icontexthome"
                  >
                    Home
                  </h1>
                </div>
              </Link>
              <Link
                to="/profilescreen"
                className={
                  location.pathname === "/profilescreen" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaUser></FaUser>
                  </div>
                  <h1
                    className="icontext"
                  >
                    My Story
                  </h1>
                </div>
              </Link>
              <Link
                to="/datatrends"
                className={location.pathname === "/datatrends" ? "active" : ""}
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaChartBar></FaChartBar>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Student Data
                  </h1>
                </div>
              </Link>
              <Link
                to="/classscreen"
                className={location.pathname === "/classscreen" ? "active" : ""}
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaBook></FaBook>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Setup Classes
                  </h1>
                </div>
              </Link>
              {playing === true ? (
                <div className="icontextrowadmin" onClick={() => changeAudio(false)}>
                  <div className="styleiconadmin">
                    <FaVolumeUp></FaVolumeUp>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              ) : (
                <div className="icontextrowadmin" onClick={() => changeAudio(true)}>
                  <div className="styleiconadmin">
                    <FaVolumeMute></FaVolumeMute>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              )}
              <Link
                to="/optionsscreen"
                className={
                  location.pathname === "/optionsscreen" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaCog></FaCog>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Settings
                  </h1>
                </div>
              </Link>
            </div>
          )}
          {user === "superadmin" && (
            <div className="alignMenuOptions">
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadminhome">
                    <FaHome></FaHome>
                  </div>
                  <h1
                    className="icontexthome"
                  >
                    Home
                  </h1>
                </div>
              </Link>
              <Link
                to="/profilescreen"
                className={
                  location.pathname === "/profilescreen" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaUser></FaUser>
                  </div>
                  <h1
                    className="icontext"
                  >
                    My Story
                  </h1>
                </div>
              </Link>
              <Link
                to="/superadmindatatrends"
                className={
                  location.pathname === "/superadmindatatrends" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaChartBar></FaChartBar>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Student Data
                  </h1>
                </div>
              </Link>
              <Link
                to="/schoolsscreen"
                className={
                  location.pathname === "/schoolsscreen" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaSchool></FaSchool>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Setup Schools
                  </h1>
                </div>
              </Link>
              {playing === true ? (
                <div className="icontextrowadmin" onClick={() => changeAudio(false)}>
                  <div className="styleiconadmin" >
                    <FaVolumeUp></FaVolumeUp>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              ) : (
                <div className="icontextrowadmin" onClick={() => changeAudio(true)}>
                  <div className="styleiconadmin">
                    <FaVolumeMute></FaVolumeMute>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              )}
              <Link
                to="/optionsscreen"
                className={
                  location.pathname === "/optionsscreen" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaCog></FaCog>
                  </div>
                  <h1
                    className="icontext"
                  >
                    Settings
                  </h1>
                </div>
              </Link>
            </div>
          )}
          {user === "123wellness" && (
            <div className="alignMenuOptions">
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadminhome">
                    <FaHome></FaHome>
                  </div>
                  <h1 className="icontexthome">Home</h1>
                </div>
              </Link>
              <Link
                to="/profilescreen"
                className={
                  location.pathname === "/profilescreen" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaUser></FaUser>
                  </div>
                  <h1 className="icontext">My Story</h1>
                </div>
              </Link>
              <Link
                to="/wellnessdatatrends"
                className={
                  location.pathname === "/wellnessdatatrends" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaChartBar></FaChartBar>
                  </div>
                  <h1 className="icontext">Student Data</h1>
                </div>
              </Link>
              <Link
                to="/wellnessstudents"
                className={
                  location.pathname === "/wellnessstudents" ? "active" : ""
                }
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaSchool></FaSchool>
                  </div>
                  <h1 className="icontext">Setup Districts</h1>
                </div>
              </Link>
             
              {/* <Link
                to="/wellnessadministrator"
                style={{ textDecoration: "none" }}
              >
                <div className="icontextrowwellness">
                  <div className="styleiconwellness">
                    <FaSchool></FaSchool>
                  </div>
                  {location.pathname === "/wellnessadministrator" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Districts
                    </h1>
                  ) : (
                    <h1 className="icontext">Districts</h1>
                  )}{" "}
                </div>
              </Link> */}
              {playing === true ? (
                <div className="icontextrowadmin" onClick={() => changeAudio(false)}>
                  <div className="styleiconadmin">
                    <FaVolumeUp></FaVolumeUp>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              ) : (
                <div className="icontextrowadmin" onClick={() => changeAudio(true)}>
                  <div className="styleiconadmin">
                    <FaVolumeMute></FaVolumeMute>
                  </div>
                  <h1 className="icontext"> Volume </h1>
                </div>
              )}
              <Link to="/optionsscreen"
                className={location.pathname === "/optionsscreen" ? "active" : ""}
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaCog></FaCog>
                  </div>
                  <h1 className="icontext">Settings</h1>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="schoolanddistrict">
          {/* If we bring the school back we need to convert from cleverID
           complicated because the school field changes based on if we are working 
           with clever or non clever*/}
          {/* {(user !== "123wellness" && user !== "superadmin") && <div>{school}</div>        } */}
          {user !== "123wellness" && <div>{district}</div>}
          {user === "123wellness" && <div>1-2-3 Wellness View</div>}
        </div>
        <div className="growthwell">
          <p>© Growthwell LLC 2024</p>
          <p>U.S. Patent No. 12,053,300</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Sidebar;
