import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { NoData } from "../AlertComponents";

const MAIN_CATEGORIES = [
   "Imagine positive things in the future",
   "Laugh at something funny", 
   "Remember that difficult times pass",
   "Remind myself of two good things in my life",
   "Tell myself something nice",
   "Get some sun in my eyes",
   "Count to ten taking a deep breath each time",
   "Imagine a peaceful place",
   "Listen to music or read",
   "Politely move away from a distraction",
   "Squeeze my fist",
   "Use my growth mindset",
   "Stretch",
   "Move my body",
   "Smile big",
   "write in my journal",
   "Think of 3 things I am grateful for",
   "Give someone a compliment",
   "Be mindful. Be where my feet are",
   "Imagine positive things in the future",
   "Squeeze my fist",
   "Help others",
   "Create art or poetry"
  ];

const StudentDataWellnessPracticeHelpfulnessDisplay = ({
  studentData,
  selectedEmotionColor,
}) => {
  const mainCategories = MAIN_CATEGORIES.map(p => p.trim().toLowerCase());

  // console.log("Main Categories:", mainCategories);

  const practiceHelpfulnessCounts = studentData.reduce((counts, dataPoint) => {
    const actionLowerCase = dataPoint.action ? dataPoint.action.trim().toLowerCase() : "";

    // console.log("Current Action:", actionLowerCase);

    const practiceCategory = mainCategories.includes(actionLowerCase)
      ? dataPoint.action
      : "Other";

    counts[practiceCategory] = (counts[practiceCategory] || 0) + 1;    return counts;
  }, {});

  const sortedPractices = Object.entries(practiceHelpfulnessCounts).sort(
    ([, countA], [, countB]) => countB - countA
  );

  const renderPracticeItem = (practice, count) => {
    const isOther = practice === "Other";
    const imageSrc = isOther
      ? "../images/actions/other.png"
      : `../images/actions/${practice}.png`;

    return (
      <div key={practice} className="practice-item">
        <div className="practice-image-container" data-action={practice}>
          <img src={imageSrc} alt={practice} className="practice-image" />
        </div>
        <p className="practice-caption-student-data">{`${count} ${
          count > 1 ? "times" : "time"
        }`}</p>
      </div>
    );
  };

  if (sortedPractices.length === 0) {
    return <NoData></NoData>;
  }

  return (
    <div
      className="practice-images-container-student-data"
      style={{ backgroundColor: selectedEmotionColor }}
    >
      {sortedPractices.map(([practice, count]) => renderPracticeItem(practice,
      count) )}
    </div>
  );
};

export default StudentDataWellnessPracticeHelpfulnessDisplay;
