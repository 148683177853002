import { useEffect, useState } from "react";
import moment from "moment";
import { useContext } from "react";
import { UserContext } from "../../realm/user.context";
import { fetchData } from "./FetchData";
import "./StudentData.css";
import BarGraph from "../../components/BarGraph";
import FilterRow from "./FilterRow";
import NavigationBar from "./NavigationBar";
import CongratulationsBanner, { shouldRenderBanner } from "./CongratulationsBanner";
import StudentDataWellnessSuccessTab from "./components/StudentDataWellnessSuccessTab/StudentDataWellnessSuccessTab";
import EmotionsLog from "./components/EmotionsLog";
import MyNextSteps from "../../components/MyNextSteps";
// import BigFiveWellnessData from "../../components/BigFiveWellnessData";
import BigFiveWellnessDataWrapper from "../../components/BigFiveWellnessData";
import BigFiveInfoDisplay from "../../components/BigFiveInfoDisplay";
import CustomTooltip from "./components/CustomTooltip";
import ScreenshotButtonComponent from "../../components/ScreenshotButtonComponent";
import { LoadingOverlayExtended } from "../../components/LoadingOverlayExtended";

const StudentData = (props) => {
  const { realmUser } = useContext(UserContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [state, setState] = useState({
    chosenSchool: "Choose School",
    chosenDistrict: "Choose District",
    chosenClass: "Choose Class",
    chosenStudent: "Choose Student",
    // chosenStudent: "All Students",
    // chosenSchool: "All School",
    // chosenDistrict: "All Districts",
    // chosenClass: "All Classes",
    searchData: "",
    districts: {},
    chosenTime: "month",
    chosenTimeCompare: "month",
    allWellnessPoints: [],
    counter: 0,
    studentData: {},
    threeImpactfulActions: {},
    filterAmountCurrent: moment.duration(30, "days"),
    filterAmountCompare: moment.duration(30, "days"),
    feeling: "select",
    wellnessList: [],
    loading: true,
    counts: [
      { name: "sad", current: 0, compare: 0 },
      { name: "tired", current: 0, compare: 0 },
      { name: "lonely", current: 0, compare: 0 },
      { name: "happy", current: 0, compare: 0 },
      { name: "grateful", current: 0, compare: 0 },
      { name: "calm", current: 0, compare: 0 },
      { name: "stressed", current: 0, compare: 0 },
      { name: "scared", current: 0, compare: 0 },
      { name: "angry", current: 0, compare: 0 },
    ],
    allStudents: [],
    activeComponent: 0,
    studentEmailToNameMap: {},
    filteredDataPoints: [],
    chosenEmotion: "sad",
    chosenColor: "#ADCDEA",
    studentEmotionData: {},
    chosenPercentage: "",
    bigFiveFilteredData: [],
    selectedTimes: {
      "All Day": true,
      "Early Morning": false,
      Morning: false,
      Afternoon: false,
      Evening: false,
    },
    selectedSubOptions: {},
    helpfulFilter: "All",
    wellnessPopupOpen: false,
  });

  const updateState = (updatedState) => {
    // if (
    //   props.user.user.user === "123wellness" ||
    //   props.user.user.user === "superadmin"
    // )
    //   setIsUpdating(true);
    setState((prevState) => {
      const newState = {
        ...prevState,
        ...updatedState,
      };
      // if (
      //   props.user.user.user === "123wellness" ||
      //   props.user.user.user === "superadmin"
      // ) {
      //   setTimeout(() => {
      //     setIsUpdating(false);
      //   }, 300);
      // }
      return newState;
    });
  };
  

  useEffect(() => {
    updateState({ loading: true });
    console.log(state.districts);
    fetchData(state, realmUser, props.user)
      .then((data) => {
        updateState(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        updateState({ loading: false, error: "Failed to fetch data" });
      });
  }, []);

  const BarGraphComponent = (props) => {
    const CustomTooltipWrapper = (props) => {
      return <CustomTooltip {...props} state={state} />;
    };
    return (
      <div>
        <h2 className="emotions-summary-title">Student Graphs</h2>{" "}
        <div
          style={{
            width: "90%",
            height: "50vh",
            margin: "auto",
            marginBottom: "4vh",
          }}
        >
          <BarGraph
            state={state}
            barGraphData={state.counts}
            CustomTooltip={CustomTooltipWrapper}
          />
        </div>
      </div>
    );
  };
  const WellnessSuccess = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">What Helps</h2>{" "}
        <StudentDataWellnessSuccessTab
          state={state}
          userRole={props.user.user.user}
          updateState={updateState}
        />
      </div>
    );
  };

  const EmotionsLogComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">Tiered Support</h2>{" "}
        <EmotionsLog
          state={state}
          userRole={props.user.user.user}
          updateState={updateState}
        />
      </div>
    );
  };

  const MyNextStepsComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">Next Steps</h2>{" "}
        <MyNextSteps
          impactfulActions={state.threeImpactfulActions}
          state={state}
          userRole={props.user.user.user}
        />
      </div>
    );
  };

  const BigFiveWellnessComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">Student Wellness History</h2>{" "}
        <BigFiveInfoDisplay />
        <BigFiveWellnessDataWrapper state={state} updateState={updateState} />
      </div>
    );
  };

  const components = [
    BarGraphComponent,
    WellnessSuccess,
    BigFiveWellnessComponent,
    EmotionsLogComponent,
    MyNextStepsComponent,
  ];

  const handleNavClick = (index) => {
    updateState({ activeComponent: index });
  };

  const handleNext = () => {
    updateState({
      activeComponent: (state.activeComponent + 1) % components.length,
    });
  };

  const handleBack = () => {
    updateState({
      activeComponent:
        (state.activeComponent - 1 + components.length) % components.length,
    });
  };

  const ActiveComponent = components[state.activeComponent];

  return (
    <div class="outerbackground">
      {(state.loading || isUpdating) && <LoadingOverlayExtended />}
      <div class="columndatatrends">
        <NavigationBar
          navItems={[
            "Student Graphs",
            "What Helps",
            "History",
            "Tiered Support",
            "Next Steps",
          ]}
          activeComponent={state.activeComponent}
          onNavClick={handleNavClick}
        />
        
        <div className="active-component-container">
          <CongratulationsBanner state={state} userRole={props.user.user.user} />

          <h2 className="centered-title">
            Who do you want to learn about today?
          </h2>

          <FilterRow
            state={state}
            userRole={props.user.user.user}
            updateState={updateState}
            activeComponent={state.activeComponent}
          />
          <ScreenshotButtonComponent studentDataScreen={true} congratulationsBar={shouldRenderBanner(undefined, state, props.user.user.user)}></ScreenshotButtonComponent>
          <ActiveComponent {...props} state={state} updateState={updateState} />
        </div>
        <div className="navigation-buttons">
          <button onClick={handleBack}>BACK</button>
          <button onClick={handleNext}>NEXT</button>
        </div>
      </div>
    </div>
  );
};

export default StudentData;
