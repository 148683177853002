import React from "react";
import "./StudentDataWellnessSuccessTab.css";
import StudentDataWellnessPracticeHelpfulnessDisplay from "./StudentDataWellnessPracticeHelpfulnessDisplay";
import StudentDataTimeframeGrid from "./StudentDataTimeframeGrid";
import StudentDataEmotionsSelector from "./StudentDataEmotionSelector";
import StudentDataCircleNumber from "./StudentDataCircleNumber";
import StudentDataHeaderBox from "./StudentDataHeaderBox";
import { FaInfoCircle } from "react-icons/fa";
import { SelectSearchData } from "../AlertComponents";
import { handleEmotion } from "../../handlers/emotionHandler2";

const StudentDataWellnessSuccessTab = (props) => {
  const handleAllEmotionsClick = () => {
    let newState = handleEmotion(props.state, "all emotions");
    props.updateState(newState);
  };

  const isDataSelected =
    props.state.chosenDistrict !== "Choose District" &&
    props.state.chosenSchool !== "Choose School" &&
    props.state.chosenClass !== "Choose Class";

  if (!isDataSelected){
    return(
      <SelectSearchData
        screenType={"Wellness Success"}
      />
    )
  }  
  return (
    <div className="wellness-success">
      
      <StudentDataHeaderBox
        userRole={props.userRole}
        state={props.state}
        chosenEmotion={props.state.chosenEmotion}
        handleAllEmotionsClick={handleAllEmotionsClick}
      />

      <div className="emotion-selection-box">
        <div className="emotion-header">
          <StudentDataCircleNumber number={1} />
          <p className="class-info-text-one">
          {props.state.chosenStudent === "All Students" || props.state.chosenSchool === "All Schools" ? "HAVE FELT" : "HAS FELT"}  <strong>{props.state.chosenEmotion.toUpperCase()}</strong>
          </p>
        </div>
        <div className="all-emotions-grid">
          <button
            className={`all-emotions-button ${
              props.state.chosenEmotion === "all emotions" ? "selected" : ""
            }`}
            onClick={handleAllEmotionsClick}
          >
            <img src={"images/emotions/all_emotions.png"} alt={"happy"} /> All Emotions
          </button>
          <StudentDataEmotionsSelector
            chosenEmotion={props.state.chosenEmotion}
            state={props.state}
            updateState={props.updateState}
          />
        </div>
      </div>

      <div className="timeframe-selection-box">
        <div className="timeframe-header">
          <StudentDataCircleNumber number={2} />
          <p className="class-info-text">
            IN THE<strong> LAST {props.state.chosenTime.toUpperCase()}</strong>
          </p>
        </div>
        <StudentDataTimeframeGrid
          selectedTimeframe={props.state.chosenTime}
          state={props.state}
          updateState={props.updateState}
          selectedEmotionColor={props.state.chosenColor}
        />
      </div>

      <div className="helpful-practices-box">
        <div className="practices-header">
          <StudentDataCircleNumber number={3} />
          <p className="class-info-text-three">
            THESE <strong>PRACTICES HAVE HELPED</strong>
          </p>
        </div>
        <div className="practices-display">
          <StudentDataWellnessPracticeHelpfulnessDisplay
            studentData={props.state.filteredDataPoints}
            selectedEmotionColor={props.state.chosenColor}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentDataWellnessSuccessTab;
