import _ from "lodash";
import "./StudentsScreen.css";
import { CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { UserContext } from "../../../realm/user.context";
import PersonIcon from "@mui/icons-material/Person";
import ReactDataSheet from "react-datasheet";
import React, { useRef, useState, useEffect, useContext } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import "react-toastify/dist/ReactToastify.css";
import "./toast-overwrites.css";
import {
  deleteClass,
  fetchAllSchoolsInDistrict,
  fetchClassesWithName,
  fetchSchoolsInfoByCleverId,
  fetchSchoolsInfoByName,
  getAllUsersFromSchool,
  getCleverUsersFromSchool,
  getNonCleverUsersFromSchool,
  insertClass,
  insertProfiles,
  insertSchool,
  loadAllClassesFromSchool,
  loadAllClassesFromSchools,
  loadAllClassesFromUser,
  loadAllClassesFromNonCleverUser,
  loadClassesFromCleverIds,
  loadProfilesFromIds,
  updateClassByID,
  updateClassName,
  updateProfile,
  updateProfiles,
  filterAdminData,
  addToClassAndProvision,
  removeUserFromClass,
  updateSchool,
  loadDistricts,
  loadSchools2,
  insertDistrict,
  loadNonCleverClassesFromCleverSchool,
  updateDistrict,
  updateDistrictV2,
  loadProfile,
  insertSchoolV2,
  loadAllClassesFromSchoolV2,
  removeClassFromSchool,
  getUsersBySchoolAndDistrict,
} from "../../../realm/graphqlQueries";
import Spacer from "../../../components/Spacer";
import { deleteUser } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import CustomEditor from "../../../components/CustomEditor";
import StudentData from "../../StudentData/StudentData";

const MAX_GRID_ROWS = 250;

const STARTING_STUDENT_GRID = [
  [
    { readOnly: true, value: "", width: "20vw" },
    { value: "Student Emails", width: "30vw", readOnly: true },
    // { value: "Class", width: "20vw", readOnly: true },
  ],
].concat(
  _.range(1, MAX_GRID_ROWS * 4 + 1).map((id) => [
    { readOnly: true, value: `Student ${id}` },
    { readOnly: false, value: "" },
    // { value: "" },
  ])
);

const STARTING_SUPER_ADMIN_GRID = [
  [
    { readOnly: true, value: "", width: "20vw" },
    { value: "Super Admin Emails", width: "30vw", readOnly: true },
    // { value: "Class", width: "20vw", readOnly: true },
  ],
].concat(
  _.range(1, 20 + 1).map((id) => [
    { readOnly: true, value: `Super Admin ${id}` },
    { readOnly: false, value: "" },
    // { value: "" },
  ])
);

const STARTING_ADMIN_GRID = [
  [
    { readOnly: true, value: "", width: "20vw" },
    { value: "Admin Emails", width: "30vw", readOnly: true },
    // { value: "Class", width: "20vw", readOnly: true },
  ],
].concat(
  _.range(1, 20 + 1).map((id) => [
    { readOnly: true, value: `Admin ${id}` },
    { readOnly: false, value: "" },
    // { value: "" },
  ])
);

const STARTING_TEACHER_GRID = [
  [
    { readOnly: true, value: "", width: "20vw" },
    { value: "Teacher Emails", width: "30vw", readOnly: true },
    // { value: "Class", width: "20vw", readOnly: true },
  ],
].concat(
  _.range(1, MAX_GRID_ROWS + 1).map((id) => [
    { readOnly: true, value: `Teacher ${id}` },
    { readOnly: false, value: "" },
    // { value: "" },
  ])
);

const SchoolButton = ({
  schoolData,
  onMobile,
  selectedSchool,
  setSelectedSchool,
}) => {
  const [hovering, setHovering] = useState(false);
  const nonCleverSchool = !schoolData.cleverId;
  const selected = selectedSchool === schoolData.name;
  const highlightedColor = nonCleverSchool ? "#4cc68d" : "#1464ff";
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => setSelectedSchool(selected ? undefined : schoolData.name)}
      style={{
        padding: "2vmin",
        textAlign: "center",
        borderRadius: "5px",
        borderWidth: "2.5px",
        borderStyle: "solid",
        marginBottom: "2.5vmin",
        borderColor: highlightedColor,
        transition: "background-color 0.5s",
        cursor: hovering ? "pointer" : "default",
        backgroundColor: selected || hovering ? highlightedColor : "#fff",
      }}
    >
      <p
        style={{
          margin: 0,
          width: "25vw",
          fontSize: "3vmin",
          fontWeight: "bold",
          textAlign: "center",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {schoolData.name}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {schoolData.classes.length === 0
          ? "No classes"
          : `${schoolData.classes.length} classes`}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {schoolData.admins.length === 0
          ? "No admins"
          : `${schoolData.admins.length} admin${
              schoolData.admins.length === 1 ? "" : "s"
            }`}
      </p>

      {selected && (
        <p
          style={{
            color: highlightedColor,
            fontWeight: "bold",
            paddingTop: "2vmin",
            position: "absolute",
            marginTop: "2.5px",
            marginBottom: 0,
            fontSize: "2.5vmin",
            transform: `translate(${onMobile ? "-8vmin" : "-2vmin"} )`,
          }}
        >
          Click here to view more schools
        </p>
      )}
    </div>
  );
};

const SyncWithCleverButton = ({
  syncing,
  realmUser,
  districtId,
  setSyncing,
  refreshData,
}) => {
  return (
    <div
      style={{ marginTop: "2vmin" }}
      id="SyncWithCleverButton"
      onClick={() => {
        if (!syncing) {
          setSyncing(true);
          realmUser
            .callFunction("syncWithClever", {
              district_id: districtId,
            })
            .then(() => refreshData().finally(() => setSyncing(false)))
            .catch(() => {
              setSyncing(false);
            });
        }
      }}
    >
      Sync With Clever
    </div>
  );
};

const PersonBubbleSubstitute = ({
  arr,
  data,
  color,
  setArr,
  selected,
  onMobile,
  setPerson,
}) => {
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (hovering) {
      const copy = JSON.parse(JSON.stringify(data));
      copy.displayName = data.email;
      setPerson(copy);
    } else {
      setPerson(undefined);
    }
  }, [hovering]);

  return (
    <div
      onClick={() => {
        if (arr) {
          if (!selected) {
            setArr(arr.concat([data]));
          } else {
            const foundIndex = arr.findIndex((item) => {
              const usingClever = data.cleverId ? true : false;
              if (usingClever) {
                return data.cleverId === item.cleverId;
              } else {
                return data._id === item._id;
              }
            });
            if (foundIndex !== -1) {
              arr.splice(foundIndex, 1);
              setArr(arr);
            }
          }
        }
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        margin: "1vmin",
        display: "flex",
        padding: "1vmin",
        overflow: "scroll",
        borderWidth: "5px",
        borderColor: color,
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "center",
        borderStyle: "solid",
        maxWidth: onMobile ? "40vmax" : "25vmax",
        // height: onMobile ? "5vmax" : "2.5vmax",
        cursor: hovering ? "pointer" : "default",
        backgroundColor: selected ? color : "#fff",
      }}
    >
      <p
        style={{
          margin: 0,
          fontWeight: "bold",
          color: selected ? "#fff" : color,
          fontSize: onMobile ? "5vmin" : "3vmin",
        }}
      >
        {data.email}
      </p>
    </div>
  );
};

const PersonBubble = ({
  arr,
  data,
  color,
  setArr,
  selected,
  onMobile,
  setPerson,
}) => {
  const [hovering, setHovering] = useState(false);
  const [displayName, setDisplayName] = useState();
  const [changingName, setChangingName] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const { realmUser } = useContext(UserContext);

  useEffect(() => {
    if (data.cleverId) {
      const [first, last] = data.displayName.split(" ");
      if (first && last) {
        let sliced = first.slice(0, 6);
        if (sliced !== first) {
          sliced += "...";
        }
        setDisplayName(`${sliced} ${last[0]}`);
      }
    } else if (data.displayName) {
      const [first, last] = data.displayName.split(" ");
      if (first && last) {
        setDisplayName(`${first[0]}${last[0]}`);
      }
    }
  }, []);

  useEffect(() => {
    if (hovering) {
      if (!displayName) {
        const copy = JSON.parse(JSON.stringify(data));
        copy.displayName = data.email;
        setPerson(copy);
      } else {
        setPerson(data);
      }
    } else {
      setPerson(undefined);
    }
  }, [hovering]);

  return (
    <div
      onClick={() => {
        if (arr) {
          if (!selected) {
            setArr(arr.concat([data]));
          } else {
            const foundIndex = arr.findIndex((item) => {
              const usingClever = data.cleverId ? true : false;
              if (usingClever) {
                return data.cleverId === item.cleverId;
              } else {
                return data._id === item._id;
              }
            });
            if (foundIndex !== -1) {
              arr.splice(foundIndex, 1);
              setArr(arr);
            }
          }
        } else if (data.user === "student") {
          setChangingName(!changingName);
        }
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        overflow: "hidden",
        margin: "1vmin",
        display: "flex",
        padding: "3vmin",
        borderWidth: "5px",
        borderColor: color,
        borderRadius: "50%",
        alignItems: "center",
        borderStyle: "solid",
        justifyContent: "center",
        width: onMobile ? "5vmax" : "2.5vmax",
        height: onMobile ? "5vmax" : "2.5vmax",
        cursor: hovering ? "pointer" : "default",
        backgroundColor: selected ? color : "#fff",
      }}
    >
      {!displayName ? (
        <PersonIcon
          style={{
            color: selected ? "#fff" : color,
            fontSize: onMobile ? "5vmin" : "3vmin",
          }}
        />
      ) : (
        <p
          style={{
            margin: 0,
            fontWeight: "bold",
            textAlign: "center",
            color: selected ? "#fff" : color,
            fontSize: onMobile ? "3vmin" : "2vmin",
          }}
        >
          {displayName}
        </p>
      )}
      <div
        onClick={() => setChangingName(false)}
        style={{
          cursor: "default",
          top: 0,
          left: 0,
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.5s",
          opacity: changingName ? 1 : 0,
          width: changingName ? "100vw" : 0,
          height: changingName ? "100vh" : 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            cursor: "default",
            opacity: changingName ? 1 : 0,
            marginLeft: onMobile ? 0 : "20vw",
            width: "40vw",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#fff",
            borderRadius: "5px",
            padding: "2vmin",
          }}
        >
          {" "}
          <p
            onClick={async () => {
              await deleteUser();
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: color,
              padding: "2vmin",
              borderRadius: "5px",
              maxWidth: "25vmin",
              color: "#fff",
              margin: 0,
              fontSize: "2.5vmin",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Click here to delete student
          </p>
          <Spacer height="5vmin" />
          <p
            style={{
              color,
              margin: 0,
              fontSize: "5vmin",
              fontWeight: "bold",
            }}
          >
            Edit Student Name
          </p>
          <p
            style={{
              color,
              margin: 0,
              fontSize: "2.5vmin",
            }}
          >
            (enter first and last name, followed by a space)
          </p>
          <Spacer height="2.5vmin" />
          <input
            type="text"
            onChange={(e) => setInputVal(e.target.value)}
            style={{
              color,
              padding: "1vmin",
              borderWidth: "2.5px",
              borderColor: color,
              borderRadius: "5px",
              borderStyle: "solid",
              fontSize: "3vmin",
            }}
          />
          <Spacer height="2.5vmin" />
          <p
            onClick={async () => {
              if (inputVal !== "" && inputVal.split(" ").length == 2) {
                await updateProfile(data.email, realmUser, {
                  displayName: inputVal,
                });
                setChangingName();
                setInputVal("");
              }
            }}
            onMouseEnter={() => setHoveringSubmit(true)}
            onMouseLeave={() => setHoveringSubmit(false)}
            style={{
              borderRadius: "5px",
              backgroundColor: color,
              padding: "1vmin",
              color: "#fff",
              cursor: hoveringSubmit && inputVal !== "" ? "pointer" : "default",
              filter: `grayscale(${
                inputVal !== "" && inputVal.split(" ").length == 2 ? 0 : 1
              })`,
            }}
          >
            Submit
          </p>
        </div>
      </div>
    </div>
  );
};

const ToolTip = ({ person, color, onMobile, mouseLocation }) => {
  const nameRef = useRef();

  useEffect(() => {
    if (person) {
      nameRef.current = person.displayName;
    }
  }, [person]);

  return (
    <div
      className="FullNameTooltip"
      style={{
        backgroundColor: color,
        opacity: person && mouseLocation ? 1 : 0,
        transition: onMobile ? "none" : "opacity 0.25s",
        transform: `translateX(${
          mouseLocation ? mouseLocation.x + 10 : 0
        }px) translateY(${mouseLocation ? mouseLocation.y + 10 : 0}px)`,
      }}
    >
      {onMobile ? (person ? person.displayName : "") : nameRef.current}
    </div>
  );
};

const ClassPreview = ({
  onMobile,
  classData,
  setPerson,
  allStudents,
  allTeachers,
  setTeachers,
  setStudents,
  refreshData,
  setClassName,
  setEditingClass,
  saveChanges,
}) => {
  const [studentsInClass, setStudentsInClass] = useState();
  const [teachersInClass, setTeachersInClass] = useState();
  const [studentGrid, setStudentGrid] = useState(
    JSON.parse(JSON.stringify(STARTING_STUDENT_GRID))
  );
  const [teacherGrid, setTeacherGrid] = useState(
    JSON.parse(JSON.stringify(STARTING_TEACHER_GRID.slice(0, 101)))
  );
  const [studentsExpanded, setStudentsExpanded] = useState(false);

  const [deleting, setDeleting] = useState(false);

  const { realmUser } = useContext(UserContext);

  const usingClever = useRef(classData.cleverId ? true : false);

  const [saving, setSaving] = useState(false);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [originalTeachers, setOriginalTeachers] = useState([]);

  useEffect(() => {
    const inClass = allStudents.filter((s) =>
      classData.students.includes(usingClever.current ? s.cleverId : s.email)
    );
    for (let i = 0; i < inClass.length; i++) {
      studentGrid[i + 1][1].value = inClass[i].email;
    }
    const teachers = allTeachers.filter((t) =>
      classData.teachers.includes(usingClever.current ? t.cleverId : t.email)
    );

    for (let i = 0; i < teachers.length; i++) {
      teacherGrid[i + 1][1].value = teachers[i].email;
    }

    // Dont include extra boxes.
    // TODO: Probably a better and more stateful solution is needed, this is a band-aid fix
    const finalStudentGrid = studentGrid.slice(0, inClass.length + 1);
    const finalTeacherGrid = teacherGrid.slice(0, teachers.length + 1);
    setStudentGrid(finalStudentGrid);
    setTeacherGrid(finalTeacherGrid);

    setStudentsInClass(inClass);
    setOriginalStudents([...inClass]);
    setOriginalTeachers([...teachers]);
    setTeachersInClass(teachers);
  }, []);

  if (!studentsInClass || !teachersInClass) {
    return <></>;
  }

  return (
    <div
      onMouseEnter={() => {
        if (!localStorage.getItem("receivedClassPreview")) {
          toast.info(
            "This is a class preview. Notice your teacher(s) in the area below.",
            { closeOnClick: true, autoClose: 25000 }
          );
          toast.info(
            "If you make changes, click the refresh icon to save them.",
            { closeOnClick: true, autoClose: 25000 }
          );
          toast.info("If you'd like to delete a class, click the trash icon.", {
            closeOnClick: true,
            autoClose: 25000,
          });

          localStorage.setItem("receivedClassPreview", true);
        }
      }}
      className="SSV2ClassPreview mod"
      style={{
        backgroundColor: usingClever.current ? "#1464FF" : "#4cc68d",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        {saving && <LoadingOverlay></LoadingOverlay>}
        <div style={{ flex: 1 }} />
        <p
          className="SSV2CPTitle"
          style={{
            textDecoration: "underline",
            textDecorationColor: "#FFF",
            textDecorationThickness: "2.5px",
          }}
        >
          {classData.name}
        </p>
        <div style={{ flex: 1 }} />
        {!usingClever.current && (
          <div
            className="SettingsWrapper"
            style={{ marginRight: "1vmin" }}
            onClick={async () => {
              // TODO sync teachers?
              const syncedStudents = studentGrid
                .slice(1)
                .filter((item) => item[1].value !== "")
                .map((item) => item[1].value);
              const originalEmails = studentsInClass.map((s) => s.email);

              const toAdd = syncedStudents.filter(
                (s) => !originalEmails.includes(s)
              );
              const toRemove = originalEmails.filter(
                (s) => !syncedStudents.includes(s)
              );

              for (const email of toAdd) {
                const foundUser = allStudents.filter((u) => u.email === email);
                await addToClassAndProvision(
                  email,
                  classData,
                  foundUser.length === 0 ? undefined : foundUser[0],
                  realmUser
                );
              }
              for (const email of toRemove) {
                const foundUser = allStudents.filter((u) => u.email === email);
                await removeUserFromClass(
                  email,
                  classData,
                  foundUser,
                  realmUser
                );
              }

              await refreshData();
            }}
          >
            <SyncIcon
              style={{
                color: "#fff",
                fontSize: "3vmin",
              }}
            />
          </div>
        )}
        {!usingClever.current && (
          <div
            className="SettingsWrapper"
            onClick={async () => {
              setDeleting(true);
              // try {
              //   await deleteClass({ _id: classData._id }, realmUser);
              // } finally {
              //   await refreshData();
              // }
            }}
          >
            <DeleteIcon style={{ color: "#fff", fontSize: "3vmin" }} />
          </div>
        )}
      </div>
      <Spacer height="2vmin" />

      {usingClever.current && !studentsExpanded && (
        <>
          <p
            className="SSV2CPTitle"
            style={{
              textAlign: "center",
              fontWeight: "normal",
              alignSelf: "flex-start",
            }}
          >
            {(() => {
              switch (teachersInClass.length) {
                case 0:
                  return "No Teachers";
                case 1:
                  return `Teacher: ${
                    teachersInClass[0].displayName ?? teachersInClass[0].email
                  }`;
                default:
                  return `Teachers: ${teachersInClass
                    .map((t) => t.displayName ?? t.email)
                    .join(", ")}`;
              }
            })()}
          </p>
          <Spacer height="2vmin" />
          <p
            onClick={() => setStudentsExpanded(true)}
            style={{
              margin: 0,
              cursor: "pointer",
              fontSize: "3vmin",
              fontWeight: "bold",
              alignSelf: "center",
              padding: "1vmin",
              borderRadius: "5px",
              backgroundColor: "#FFF",
              color: usingClever.current ? "#1464FF" : "#4cc68d",
            }}
          >
            View Students
          </p>
        </>
      )}
      {usingClever.current && studentsExpanded && (
        <>
          <p
            className="SSV2CPTitle"
            style={{
              textAlign: "center",
              fontWeight: "normal",
              alignSelf: "flex-start",
            }}
          >
            {(() => {
              switch (teachersInClass.length) {
                case 0:
                  return "No Teachers";
                case 1:
                  return `Teacher: ${
                    teachersInClass[0].displayName ?? teachersInClass[0].email
                  }`;
                default:
                  return `Teachers: ${teachersInClass
                    .map((t) => t.displayName ?? t.email)
                    .join(", ")}`;
              }
            })()}
          </p>
          <Spacer height="2vmin" />
          <ReactDataSheet
            dataEditor={(props) => <CustomEditor {...props} />}
            data={studentGrid}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={(changes) => {
              const grid = studentGrid.map((row) => [...row]);
              changes.forEach(({ cell, row, col, value }) => {
                grid[row][col] = { ...grid[row][col], value };
              });
              setStudentGrid(grid);
            }}
          />
          <Spacer height="2vmin" />
          <p
            onClick={() => setStudentsExpanded(false)}
            style={{
              margin: 0,
              cursor: "pointer",
              fontSize: "3vmin",
              fontWeight: "bold",
              alignSelf: "center",
              padding: "1vmin",
              borderRadius: "5px",
              backgroundColor: "#FFF",
              color: usingClever.current ? "#1464FF" : "#4cc68d",
            }}
          >
            Hide Students
          </p>
        </>
      )}

      {/* <Spacer height="1vmin" /> */}
      {/* <div
        className="SSV2Backdrop"
        style={{
          flexWrap: "wrap",
          flexDirection: "column",
          backgroundColor: usingClever.current ? "#1464FF" : "#4cc68d",
        }}
      >
        {teachersInClass.map((t, i) => (
          <p
            key={i}
            style={{
              fontSize: "3vmin",
              color: "#fff",
              margin: "0 1vmin",
            }}
          >{`\t${t.displayName ?? t.email}`}</p>
        ))}
      </div> */}

      {/* <div className="SSV2Backdrop">
        {teachersInClass.map((t, i) => (
          <PersonBubble
            key={i}
            data={t}
            onMobile={onMobile}
            setPerson={setPerson}
            color={usingClever.current ? "#1464FF" : "#4cc68d"}
          />
        ))}
      </div>  */}

      {/* TODO: Fix this it causes double renders for non-clever school */}
      {!usingClever.current &&
        (!studentsExpanded ? (
          <>
            <p
              onClick={() => setStudentsExpanded(true)}
              style={{
                margin: 0,
                cursor: "pointer",
                fontSize: "3vmin",
                fontWeight: "bold",
                alignSelf: "center",
                padding: "1vmin",
                borderRadius: "5px",
                backgroundColor: "#FFF",
                color: usingClever.current ? "#1464FF" : "#4cc68d",
              }}
            >
              Edit Class
            </p>
          </>
        ) : (
          <>
            <p
              className="SSV2CPTitle"
              style={{
                textAlign: "left",
                fontWeight: "normal",
                alignSelf: "flex-start",
              }}
            >
              Teachers
            </p>
            <Spacer height="1vmin" />
            <div className="SSV2BackdropTeacher">
              <ReactDataSheet
                dataEditor={(props) => <CustomEditor {...props} />}
                data={teacherGrid}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes) => {
                  const grid = teacherGrid.map((row) => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });
                  setTeacherGrid(grid);
                }}
              />
              {/* {studentsInClass.map((s, i) => (
          <PersonBubble
            key={i}
            data={s}
            onMobile={onMobile}
            setPerson={setPerson}
            color={usingClever.current ? "#1464FF" : "#4cc68d"}
          />
        ))} */}
            </div>
            <Spacer height="1vmin" />

            {/* <p
              onClick={async () => {
                if (!saving) {
                  setSaving(true);
                  await saveChanges(
                    "teacher",
                    teacherGrid,
                    originalTeachers,
                    setOriginalTeachers,
                    classData.name
                  );
                  setSaving(false);
                }
              }}
              style={{
                textAlign: "center",
                color: "white",
                marginTop: "2vmin",
                borderRadius: "5px",
                borderStyle: "solid",
                borderColor: "white",
                padding: "1vmin",
                borderWidth: "2.5px",
                fontSize: "2vmin",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Save Teachers
            </p> */}
            <p
              className="SSV2CPTitle"
              style={{
                textAlign: "left",
                fontWeight: "normal",
                alignSelf: "flex-start",
              }}
            >
              Students
            </p>
            <Spacer height="1vmin" />
            <div className="SSV2Backdrop">
              <ReactDataSheet
                dataEditor={(props) => <CustomEditor {...props} />}
                data={studentGrid}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes) => {
                  const grid = studentGrid.map((row) => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });
                  setStudentGrid(grid);
                }}
              />
              {/* {studentsInClass.map((s, i) => (
          <PersonBubble
            key={i}
            data={s}
            onMobile={onMobile}
            setPerson={setPerson}
            color={usingClever.current ? "#1464FF" : "#4cc68d"}
          />
        ))} */}
            </div>
            <p
              onClick={async () => {
                if (!saving) {
                  setSaving(true);
                  await saveChanges(
                    "teacher",
                    teacherGrid,
                    originalTeachers,
                    setOriginalTeachers,
                    classData.name,
                    true
                  );
                  await saveChanges(
                    "student",
                    studentGrid,
                    originalStudents,
                    setOriginalStudents,
                    classData.name,
                    false
                  );
                  setSaving(false);
                }
              }}
              style={{
                textAlign: "center",
                color: "white",
                marginTop: "2vmin",
                borderRadius: "5px",
                borderStyle: "solid",
                borderColor: "white",
                padding: "1vmin",
                borderWidth: "2.5px",
                fontSize: "2vmin",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Save
            </p>
            <p
              onClick={() => setStudentsExpanded(false)}
              style={{
                cursor: "pointer",
                marginTop: "2vmin",
                marginBottom: 0,
                color: "#FFF",
                fontWeight: "bold",
                fontSize: "3vmin",
                alignSelf: "center",
              }}
            >
              Hide Class
            </p>
          </>
        ))}

      <div
        onClick={() => setDeleting(false)}
        style={{
          top: 0,
          left: 0,
          display: "flex",
          position: "fixed",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          zIndex: deleting ? 2 : -1,
          width: deleting ? "100vw" : "0vw",
          height: deleting ? "100vh" : "0vh",
          // transition: "width 0.5s, height 0.5s",
          backgroundColor: deleting ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
        }}
      >
        {deleting && (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2.5vmin",
              borderRadius: "5px",
              backgroundColor: "#FFF",
              // transition: "width 0.5s, height 0.5s",
              width: deleting ? "75vmin" : "0vmin",
              marginLeft: onMobile ? "0" : "20vmin",
              // height: deleting ? "75vmin" : "0vmin",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "5vmin",
                fontWeight: "bold",
                textAlign: "center",
                // transition: "color 0.5s",
                zIndex: deleting ? 2 : -1,
                color: deleting ? "#4cc68d" : "#FFF",
              }}
            >
              Are you sure you want to delete '{classData.name}'?
            </p>
            <Spacer height="2.5vmin" />
            <p
              style={{
                margin: 0,
                fontSize: "3vmin",
                // fontWeight: "bold",
                textAlign: "center",
                // transition: "color 0.5s",
                zIndex: deleting ? 2 : -1,
                color: deleting ? "#4cc68d" : "#FFF",
              }}
            >
              This cannot be undone.
            </p>
            <Spacer height="5vmin" />
            <p
              onClick={async () => {
                try {
                  //console.log(classData);
                  setSaving(true);
                  await deleteClass({ _id: classData._id }, realmUser);
                  await removeClassFromSchool(
                    classData.school,
                    classData.district,
                    realmUser,
                    classData.name
                  );
                } finally {
                  await refreshData();
                  setDeleting(false);
                  setSaving(false);
                }
              }}
              style={{
                cursor: "pointer",
                alignSelf: "center",
                textAlign: "center",
                fontSize: "3.5vmin",
                width: "20vw",
                margin: 0,
                backgroundColor: "#4cc68d",
                padding: "2vmin",
                borderRadius: "5px",
                color: "#FFF",
              }}
            >
              Yes, I'm Sure.
            </p>
            <Spacer height="2.5vmin" />
            <p
              onClick={() => setDeleting(false)}
              style={{
                cursor: "pointer",
                alignSelf: "center",
                textAlign: "center",
                width: "20vw",
                fontSize: "3.5vmin",
                margin: 0,
                backgroundColor: "#4cc68d",
                padding: "2vmin",
                borderRadius: "5px",
                color: "#FFF",
              }}
            >
              No, take me back.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const AddClassButton = ({ setCreatingClass }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <>
      <div
        onClick={() => setCreatingClass(true)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          padding: "2vmin",
          fontSize: "3vmin",
          borderRadius: "5px",
          borderWidth: "2.5px",
          borderStyle: "solid",
          borderColor: "#4cc68d",
          color: hovering ? "#fff" : "#4cc68d",
          cursor: hovering ? "pointer" : "default",
          backgroundColor: hovering ? "#4cc68d" : "#fff",
          transition: "color 0.5s, background-color 0.5s",
        }}
      >
        Create New Class
      </div>
    </>
  );
};

const CreateClassScreen = ({
  school,
  onMobile,
  students,
  teachers,
  setPerson,
  className,
  setTeachers,
  setStudents,
  allStudents,
  allTeachers,
  refreshData,
  editingClass,
  setClassName,
  creatingClass,
  isCleverSchool,
  setEditingClass,
  setCreatingClass,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const [studentGrid, setStudentGrid] = useState(
    JSON.parse(JSON.stringify(STARTING_STUDENT_GRID.slice(0, 51)))
  );
  const [teacherGrid, setTeacherGrid] = useState(
    JSON.parse(JSON.stringify(STARTING_TEACHER_GRID.slice(0, 4)))
  );

  const [typingStudents, setTypingStudents] = useState(false);
  const [typingTeachers, setTypingTeachers] = useState(false);

  const [typedStudents, setTypedStudents] = useState("");
  const [typedTeachers, setTypedTeachers] = useState("");

  const { realmUser } = useContext(UserContext);
  const isCleverClass = editingClass && editingClass.cleverId ? true : false;

  let enteredTeachers = teacherGrid.slice(1).filter((d) => d[1].value !== "");
  let enteredStudents = studentGrid.slice(1).filter((d) => d[1].value !== "");
  let canSubmit =
    // enteredStudents.length > 0 &&
    // enteredTeachers.length > 0 &&
    className !== "";
  if (typingStudents) {
    canSubmit = typedStudents !== "";
  }
  if (typingTeachers) {
    canSubmit = typedTeachers !== "";
  }

  if (!school) {
    return <></>;
  }

  if (submitting) {
    return <LoadingOverlay></LoadingOverlay>;
  }

  return (
    <div
      onClick={() => {
        if (editingClass) {
          setStudents([]);
          setTeachers([]);
          setClassName("");
          setEditingClass(undefined);
        } else {
          setCreatingClass(false);
        }
      }}
      style={{
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        position: "fixed",
        overflow: "scroll",
        alignItems: "center",
        justifyContent: "center",
        zIndex: creatingClass || editingClass ? 0 : -1,
        transition: "z-index 0.1s, background-color 0.5s",
        backgroundColor:
          creatingClass || editingClass
            ? "rgba(0, 0, 0, 0.5)"
            : "rgba(0, 0, 0, 0)",
      }}
    >
      <div
        // prevents clickout from happening
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "40vmax",
          display: "flex",
          maxHeight: "80%",
          overflow: "scroll",
          borderRadius: "5px",
          position: "relative",
          alignItems: "center",
          flexDirection: "column",
          marginLeft: onMobile ? "0vmin" : "20vw",
          transition: "opacity 0.1s, padding 0.1s",
          marginBottom: onMobile ? "25vmin" : "0vmin",
          zIndex: creatingClass || editingClass ? 2 : -1,
          opacity: creatingClass || editingClass ? 1 : 0,
          backgroundColor: isCleverClass
            ? "#1464ff"
            : editingClass || creatingClass
            ? "#fff"
            : "transparent",
          padding: creatingClass || editingClass ? "2vmin" : "0vmin",
        }}
      >
        {isCleverClass && (
          <>
            <p
              style={{
                margin: 0,
                color: "#fff",
                fontSize: "4vmin",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Edit Class Name
            </p>
            <Spacer height="2vmin" />
            <input
              type="text"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              style={{
                flex: 1,
                width: "80%",
                color: "#fff",
                padding: "1vmin",
                fontSize: "3vmin",
                borderColor: "#fff",
                borderRadius: "5px",
                borderStyle: "solid",
                borderWidth: "2.5px",
                backgroundColor: "#1464ff",
              }}
            />
            <Spacer height="2vmin" />
            <div
              onMouseEnter={() => setHoveringSubmit(true)}
              onMouseLeave={() => setHoveringSubmit(false)}
              onClick={async () => {
                if (canSubmit && !submitting) {
                  setSubmitting(true);
                  try {
                    const result = await fetchClassesWithName(
                      school.cleverId ?? school._id,
                      className,
                      realmUser
                    );

                    if (!result) {
                      if (editingClass) {
                        await updateClassByID(
                          editingClass._id,
                          editingClass.name,
                          realmUser,
                          {
                            name: className,
                            students: students.map((s) =>
                              isCleverSchool ? s.cleverId : s.email
                            ),
                            teachers: teachers.map((t) =>
                              isCleverSchool ? t.cleverId : t.email
                            ),
                          }
                        );
                      } else {
                        await insertClass(
                          {
                            name: className,
                            district: school.district,
                            students: students.map((s) =>
                              isCleverSchool ? s.cleverId : s.email
                            ),
                            teachers: teachers.map((t) =>
                              isCleverSchool ? t.cleverId : t.email
                            ),
                            school: isCleverSchool
                              ? school.cleverId
                              : school.name,
                          },
                          realmUser
                        );
                      }
                      await refreshData();
                      setEditingClass(false);
                      setCreatingClass(false);
                    }
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setClassName("");
                    setSubmitting(false);
                  }
                }
              }}
              style={{
                margin: 0,
                padding: "1vmin",
                borderRadius: "5px",
                borderWidth: "2.5px",
                borderStyle: "solid",
                borderColor: "#fff",
                color: canSubmit ? "#1464ff" : "#fff",
                backgroundColor: canSubmit ? "#fff" : "#4cc68d",
                transition: "color 0.5s, background-color 0.5s",
                cursor: hoveringSubmit && canSubmit ? "pointer" : "default",
              }}
            >
              Submit
            </div>
          </>
        )}
        {(creatingClass || editingClass) && !isCleverClass && (
          <>
            <p
              style={{
                margin: 0,
                color: "#4cc68d",
                fontSize: "4vmin",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {editingClass ? "Edit Class" : "Create New Class"}
            </p>
            {isCleverSchool && (
              <p
                style={{
                  margin: 0,
                  width: "75%",
                  color: "#4cc68d",
                  alignSelf: "center",
                  fontSize: "2.5vmin",
                  textAlign: "center",
                }}
              >
                This class will not be synced with Clever.
              </p>
            )}
            <Spacer height="2vmin" />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#4cc68d",
                  fontSize: "3vmin",
                  fontWeight: "bold",
                  alignSelf: "flex-start",
                }}
              >
                Class Name:
              </p>
              <div style={{ height: "100%", width: "2.5vmax" }} />
              <input
                type="text"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                style={{
                  flex: 1,
                  color: "#4cc68d",
                  padding: "1vmin",
                  fontSize: "3vmin",
                  borderColor: "#4cc68d",
                  borderRadius: "5px",
                  borderStyle: "solid",
                  borderWidth: "2.5px",
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <Spacer height="5vmin" />
            <p
              style={{
                margin: 0,
                color: "#4cc68d",
                fontSize: "3vmin",
                fontWeight: "bold",
              }}
            >
              Add Teachers
            </p>
            <Spacer height="2.5vmin" />
            <ReactDataSheet
              dataEditor={(props) => <CustomEditor {...props} />}
              data={teacherGrid}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={(changes) => {
                const grid = teacherGrid.map((row) => [...row]);
                changes.forEach(({ cell, row, col, value }) => {
                  grid[row][col] = { ...grid[row][col], value };
                });
                setTeacherGrid(grid);
              }}
            />
            {/* <div
              style={{
                width: "95%",
                display: "flex",
                padding: "1vmin",
                flexWrap: "wrap",
                borderRadius: "5px",
                flexDirection: "row",
                backgroundColor: "#fff",
                justifyContent: "space-evenly",
              }}
            >
              <div
                className="SelectAllButton"
                onClick={() => setStudents(allStudents.map((i) => i))}
              >
                Select All
              </div>
              <div
                className="SelectAllButton"
                onClick={() => setTypingStudents(!typingStudents)}
              >
                Type Names
              </div>
              <div style={{ flexBasis: "100%", height: 0 }} />
              {!typingStudents &&
                allStudents.map((s, i) =>
                  s.displayName ? (
                    <PersonBubble
                      key={i}
                      data={s}
                      arr={students}
                      color="#4cc68d"
                      onMobile={onMobile}
                      setArr={setStudents}
                      setPerson={setPerson}
                      selected={
                        students.find((temp) => {
                          if (s.cleverId) {
                            // in this case, using clever
                            return temp.cleverId === s.cleverId;
                          } else {
                            return temp._id === s._id;
                          }
                        })
                          ? true
                          : false
                      }
                    />
                  ) : (
                    <PersonBubbleSubstitute
                      key={i}
                      data={s}
                      arr={students}
                      color="#4cc68d"
                      onMobile={onMobile}
                      setArr={setStudents}
                      setPerson={setPerson}
                      selected={
                        students.find((temp) => {
                          if (s.cleverId) {
                            // in this case, using clever
                            return temp.cleverId === s.cleverId;
                          } else {
                            return temp._id === s._id;
                          }
                        })
                          ? true
                          : false
                      }
                    />
                  )
                )}
              {typingStudents && (
                <textarea
                  onChange={(e) => setTypedStudents(e.target.value)}
                  style={{
                    color: "#4cc68d",
                    padding: "1vmin",
                    fontSize: "3vmin",
                    marginTop: "1vmin",
                    borderRadius: "5px",
                    borderWidth: "2.5px",
                    borderColor: "#4cc68d",
                  }}
                />
              )}
            </div> */}
            <Spacer height="5vmin" />

            <p
              style={{
                margin: 0,
                color: "#4cc68d",
                fontSize: "3vmin",
                fontWeight: "bold",
              }}
            >
              Add Students
            </p>
            <Spacer height="2.5vmin" />
            <div className="createnewclasswidth">
              <ReactDataSheet
                dataEditor={(props) => <CustomEditor {...props} />}
                data={studentGrid}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes) => {
                  const grid = studentGrid.map((row) => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });
                  console.log(JSON.stringify(grid, null, "\t"));
                  setStudentGrid(grid);
                }}
              />
            </div>

            {/* <div
              style={{
                width: "95%",
                display: "flex",
                padding: "1vmin",
                flexWrap: "wrap",
                borderRadius: "5px",
                flexDirection: "row",
                backgroundColor: "#fff",
                justifyContent: "space-evenly",
              }}
            >
              <div
                className="SelectAllButton"
                onClick={() => setTeachers(allTeachers.map((i) => i))}
              >
                Select All
              </div>
              <div
                className="SelectAllButton"
                onClick={() => setTypingTeachers(!typingTeachers)}
              >
                Type Names
              </div>

              <div style={{ flexBasis: "100%", height: 0 }} />
              {!typingTeachers &&
                allTeachers.map((t, i) =>
                  t.displayName ? (
                    <PersonBubble
                      key={i}
                      data={t}
                      arr={teachers}
                      color="#4cc68d"
                      onMobile={onMobile}
                      setArr={setTeachers}
                      setPerson={setPerson}
                      selected={
                        teachers.find((temp) => {
                          if (t.cleverId) {
                            // in this case, using clever
                            return temp.cleverId === t.cleverId;
                          } else {
                            return temp._id === t._id;
                          }
                        })
                          ? true
                          : false
                      }
                    />
                  ) : (
                    <PersonBubbleSubstitute
                      key={i}
                      data={t}
                      arr={teachers}
                      color="#4cc68d"
                      onMobile={onMobile}
                      setArr={setTeachers}
                      setPerson={setPerson}
                      selected={
                        teachers.find((temp) => {
                          if (t.cleverId) {
                            // in this case, using clever
                            return temp.cleverId === t.cleverId;
                          } else {
                            return temp._id === t._id;
                          }
                        })
                          ? true
                          : false
                      }
                    />
                  )
                )}
              {typingTeachers && (
                <textarea
                  onChange={(e) => setTypedTeachers(e.target.value)}
                  style={{
                    color: "#4cc68d",
                    padding: "1vmin",
                    fontSize: "3vmin",
                    marginTop: "1vmin",
                    borderRadius: "5px",
                    borderWidth: "2.5px",
                    borderColor: "#4cc68d",
                  }}
                />
              )}
            </div>
 */}
            <div
              onMouseEnter={() => setHoveringSubmit(true)}
              onMouseLeave={() => setHoveringSubmit(false)}
              onClick={async () => {
                if (canSubmit && !submitting) {
                  setSubmitting(true);

                  try {
                    const result = await fetchClassesWithName(
                      school.cleverId ?? school._id,
                      className,
                      realmUser
                    );

                    if (!result) {
                      let studentData = studentGrid
                        .slice(1)
                        .map((d) => d.value);
                      let teacherData = teacherGrid
                        .slice(1)
                        .map((d) => d.value);

                      // let studentData = students.map((s) =>
                      //   isCleverSchool ? s.cleverId : s.email
                      // );
                      // let teacherData = teachers.map((t) =>
                      //   isCleverSchool ? t.cleverId : t.email
                      // );

                      // if (typingStudents) {
                      //   const studentEmails = typedStudents
                      //     .split("\n")
                      //     .filter((item) =>
                      //       /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                      //         item
                      //       )
                      //     );
                      //   const selectedStudents = allStudents.filter((s) =>
                      //     studentEmails.includes(s.email)
                      //   );
                      //   studentData = selectedStudents.map((s) =>
                      //     isCleverSchool ? s.cleverId : s.email
                      //   );
                      // }

                      // if (typingTeachers) {
                      //   const teacherEmails = typedTeachers
                      //     .split("\n")
                      //     .filter((item) =>
                      //       /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                      //         item
                      //       )
                      //     );
                      //   const selectedTeachers = allTeachers.filter((s) =>
                      //     teacherEmails.includes(s.email)
                      //   );
                      //   teacherData = selectedTeachers.map((s) =>
                      //     isCleverSchool ? s.cleverId : s.email
                      //   );
                      // }

                      if (editingClass) {
                        await updateClassByID(
                          editingClass._id,
                          editingClass.name,
                          realmUser,
                          {
                            name: className,
                            students: studentData,
                            teachers: teacherData,
                          }
                        );
                      } else {
                        const students = Array.from(
                          new Set(
                            studentGrid
                              .slice(1)
                              .map((item) => item[1].value)
                              .filter((i) => i !== "")
                          )
                        );
                        const teachers = Array.from(
                          new Set(
                            teacherGrid
                              .slice(1)
                              .map((item) => item[1].value)
                              .filter((i) => i !== "")
                          )
                        );

                        await insertClass(
                          {
                            students,
                            teachers,
                            name: className,
                            district: school.district,
                            school: isCleverSchool
                              ? school.cleverId
                              : school.name,
                          },
                          realmUser
                        );

                        let { classes } = school;
                        classes = Array.from(
                          new Set(classes.concat(className))
                        );
                        await updateSchool(
                          school.name,
                          school.district,
                          realmUser,
                          { classes }
                        );

                        for (const student of students) {
                          const profile = await loadProfile(student, realmUser);
                          if (profile.user !== null) {
                            await updateProfile(student, realmUser, {
                              class: profile.user.class.concat([className]),
                              district: school.district,
                              school: school.name,
                              schools: isCleverSchool
                                ? [school.cleverId]
                                : [school.name],
                              user: "student",
                            });
                          } else {
                            await addToClassAndProvision(
                              student,
                              {
                                students,
                                teachers,
                                name: className,
                                district: school.district,
                                school: school.name,
                                schools: isCleverSchool
                                  ? [school.cleverId]
                                  : [school.name],
                              },
                              undefined,
                              realmUser,
                              false
                            );
                          }
                        }
                        for (const teacher of teachers) {
                          const profile = await loadProfile(teacher, realmUser);
                          //console.log(profile);
                          if (profile.user === null) {
                            insertProfiles(
                              [
                                {
                                  count: 0,
                                  dataUse: false,
                                  email: teacher,
                                  user: "teacher",
                                  agreedToTerms: false,
                                  class: [className],
                                  school: school.name,
                                  schools: isCleverSchool
                                    ? [school.cleverId]
                                    : [school.name],
                                  wellnesstimer: "30 seconds",
                                  district: school.district,
                                },
                              ],
                              realmUser
                            );
                          } else {
                            await updateProfile(teacher, realmUser, {
                              class: profile.user.class.concat([className]),
                              user: "teacher",
                              district: school.district,
                              school: school.name,
                              schools: isCleverSchool
                                ? [school.cleverId]
                                : [school.name],
                            });
                          }
                        }
                        await updateSchool(
                          school.name,
                          school.district,
                          realmUser,
                          {
                            classes: school.classes.concat([className]),
                          }
                        );
                      }
                      await refreshData();
                      setEditingClass(false);
                      setCreatingClass(false);
                    }
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setClassName("");
                    setSubmitting(false);
                  }
                }
              }}
              style={{
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 0,
                marginTop: "2.5vmin",
                padding: "1vmin",
                borderRadius: "5px",
                borderWidth: "2.5px",
                borderStyle: "solid",
                borderColor: "#4cc68d",
                color: !canSubmit ? "#4cc68d" : "#fff",
                backgroundColor: !canSubmit ? "#fff" : "#4cc68d",
                transition: "color 0.5s, background-color 0.5s",
                cursor: hoveringSubmit && canSubmit ? "pointer" : "default",
              }}
            >
              Submit
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const AddSchoolButton = ({ refresh, district, onMobile, highlightedColor }) => {
  const [hovering, setHovering] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [adminNameBox, setAdminNameBox] = useState("");
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const [creatingSchool, setCreatingSchool] = useState(false);
  const [adminGrid, setAdminGrid] = useState(STARTING_ADMIN_GRID);

  const addingDistrict = district === undefined;

  const { realmUser } = useContext(UserContext);

  // const canSubmit = !submitting && schoolName !== "" && adminNameBox !== "";

  const getUsersToSubmit = () => {
    const toAdd = [];
    const users = adminGrid.slice(1);
    for (const item of users) {
      if (item[1].value !== "") {
        toAdd.push(item[1].value);
      }
    }
    return toAdd;
  };
  const canSubmit = !submitting && schoolName !== "";

  const submitSchool = async () => {
    const admins = getUsersToSubmit().filter((u) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(u)
    );
    // if (admins.length === 0) return;

    // // create admin accounts
    // const { toAdd, toUpdate } = await filterAdminData(admins, realmUser);

    // let addingData = toAdd.map((a) => {
    //   return {
    //     district,
    //     count: 0,
    //     email: a,
    //     class: [],
    //     user: "admin",
    //     dataUse: true,
    //     school: addingDistrict ? "" : schoolName,
    //     agreedToTerms: false,
    //     schools: addingDistrict ? [] : [schoolName],
    //     wellnesstimer: "30 seconds",
    //   };
    // });
    async function addUser(attributes) {
      let nAttributes = attributes;
      const profile = await loadProfile(attributes.email, realmUser);
      if (profile.user === null) {
        //console.log("inserting user ", attributes);
        await insertProfiles([attributes], realmUser);
        return;
      } else if (
        attributes.classes !== undefined &&
        attributes.classes !== null
      ) {
        //Classes are added to a list, other attributes simply overwrite
        let nClasses = [...Set(profile.user.class.concat(attributes.classes))];
        nAttributes.classes = nClasses;
      }
      updateProfile(attributes.email, realmUser, attributes);
    }
    //console.log(admins);

    for (const email of admins) {
      if (email !== "") {
        let userDoc = {
          email: email,
          user: "admin",
          district,
          count: 0,
          dataUse: false,
          // agreedToTerms: false,
          class: [],
          school: schoolName,
          // schools: [schoolName],
          wellnesstimer: "30 seconds",
        };
        await addUser(userDoc);
      }
    }
    // await insertProfiles(addingData, realmUser);
    // for (const updating of toUpdate) {
    //   //console.log(toUpdate);
    //   await updateProfile(
    //     updating.email,
    //     realmUser,
    //     addingDistrict
    //       ? { district: schoolName }
    //       : {
    //           schools: [...(updating.schools ?? []), schoolName],
    //         }
    //   );
    // }

    // let updatingData = await insertProfiles(adminData, realmUser);
    if (!addingDistrict) {
      await insertSchoolV2(
        { name: schoolName, classes: [], admins, district },
        realmUser
      );
      // Make a copy of the schools array and add the new school name

      // Call the updateDistrictV2 function with the updated schools array
    } else {
      await insertDistrict(
        { name: schoolName, superadmins: admins, schools: [] },
        realmUser
      );
    }

    await refresh();
    setCreatingSchool(false);
  };

  if (submitting) return <LoadingOverlay></LoadingOverlay>;

  return (
    <>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={() => setCreatingSchool(true)}
        style={{
          padding: "2vmin",
          fontSize: "3vmin",
          textAlign: "center",
          borderRadius: "5px",
          borderWidth: "2.5px",
          borderStyle: "solid",
          marginBottom: "2.5vmin",
          borderColor: highlightedColor,
          cursor: hovering ? "pointer" : "default",
          color: hovering ? "#fff" : highlightedColor,
          transition: "background-color 0.5s, color 0.5s",
          backgroundColor: hovering ? highlightedColor : "#fff",
        }}
      >
        Create {addingDistrict ? "District" : "School"}
      </div>
      <div
        onClick={() => setCreatingSchool(false)}
        style={{
          top: 0,
          left: 0,
          display: "flex",
          position: "fixed",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          transition: "opacity 0.5s",
          opacity: creatingSchool ? 1 : 0,
          width: creatingSchool ? "100vw" : 0,
          height: creatingSchool ? "100vh" : 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "60vw",
            display: "flex",
            padding: "2.5vmin",
            borderRadius: "5px",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#fff",
            opacity: creatingSchool ? 1 : 0,
            marginLeft: onMobile ? 0 : "20vw",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "5vmin",
              fontWeight: "bold",
              color: highlightedColor,
            }}
          >
            Create a {addingDistrict ? "District" : "School"}
          </p>
          <Spacer height="2.5vmin" />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                margin: 0,
                width: "12.5vw",
                fontSize: "3vmin",
                fontWeight: "bold",
                color: highlightedColor,
              }}
            >
              {addingDistrict ? "District" : "School"} Name:
            </p>
            <textarea
              onChange={(e) => setSchoolName(e.target.value)}
              style={{
                flex: 1,
                padding: "1vmin",
                fontSize: "3vmin",
                textAlign: "center",
                borderRadius: "5px",
                borderStyle: "solid",
                color: highlightedColor,
                borderColor: highlightedColor,
              }}
            />
          </div>
          <Spacer height="2.5vmin" />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                margin: 0,
                width: "12.5vw",
                fontSize: "3vmin",
                fontWeight: "bold",
                color: highlightedColor,
              }}
            >
              Add {addingDistrict ? "Superadmins" : "Admins"}:
            </p>
            {creatingSchool && (
              <ReactDataSheet
                dataEditor={(props) => <CustomEditor {...props} />}
                data={adminGrid}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes) => {
                  const grid = adminGrid.map((row) => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });
                  setAdminGrid(grid);
                }}
              />
            )}
          </div>
          <Spacer height="2.5vmin" />
          <div
            onClick={async () => {
              if (canSubmit) {
                setSubmitting(true);
                await submitSchool();
                setSubmitting(false);
              }
            }}
            onMouseEnter={() => setHoveringSubmit(true)}
            onMouseLeave={() => setHoveringSubmit(false)}
            style={{
              margin: 0,
              color: "#fff",
              padding: "1vmin",
              fontSize: "2.5vmin",
              borderRadius: "5px",
              backgroundColor: highlightedColor,
              filter: `grayscale(${canSubmit ? 0 : 1})`,
              cursor: hoveringSubmit && canSubmit ? "pointer" : "default",
            }}
          >
            Submit
          </div>
        </div>
      </div>
    </>
  );
};

const AddPeople = ({
  school,
  refresh,
  onMobile,
  allStudents,
  allTeachers,
  addingPeople,
  setAddingPeople,
}) => {
  const [text, setText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [hoveringSubmit, setHoveringSubmit] = useState(false);
  const { realmUser } = useContext(UserContext);
  const isTeacher = addingPeople === "teacher";

  // user form stuff
  const [studentGrid, setStudentGrid] = useState(STARTING_STUDENT_GRID);
  const [teacherGrid, setTeacherGrid] = useState(STARTING_TEACHER_GRID);
  const getUsersToSubmit = () => {
    if (isTeacher) {
      const teachersToAdd = [];
      const teachers = teacherGrid.slice(1);
      for (const item of teachers) {
        if (item[1].value !== "") {
          teachersToAdd.push(item[1].value);
        }
      }
      return teachersToAdd;
    } else {
      const studentsToAdd = [];
      const students = studentGrid.slice(1);
      for (const item of students) {
        if (item[1].value !== "") {
          studentsToAdd.push(item[1].value);
        }
      }
      return studentsToAdd;
    }
  };
  const canSubmit = addingPeople && getUsersToSubmit().length > 0;

  if (!school) return <></>;

  const filterPeople = () => {
    const emails = getUsersToSubmit().filter((u) => {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(u)) {
        if (isTeacher) {
          return allTeachers.find((item) => item.email === u) ? false : true;
        }
        return allStudents.find((item) => item.email === u) ? false : true;
      }
      return false;
    });

    return emails.map((e) => {
      return {
        count: 0,
        email: e,
        class: [],
        dataUse: true,
        school: school.name,
        agreedToTerms: false,
        schools: [school.name],
        district: school.district,
        wellnesstimer: "30 seconds",
        user: isTeacher ? "teacher" : "student",
      };
    });
  };

  return (
    <div
      onClick={() => setAddingPeople(false)}
      style={{
        top: 0,
        left: 0,
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        transition: "opacity 0.5s",
        opacity: addingPeople ? 1 : 0,
        width: addingPeople ? "100vw" : 0,
        height: addingPeople ? "100vh" : 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "50%",
          display: "flex",
          padding: "2.5vmin",
          borderRadius: "5px",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#fff",
          marginLeft: onMobile ? 0 : "20vw",
        }}
      >
        <p
          style={{
            margin: 0,
            width: "100%",
            color: "#4cc68d",
            fontSize: "5vmin",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Add {isTeacher ? "Teacher" : "Student"}s
        </p>
        <Spacer height="2.5vmin" />
        <div className="spreadsheetrow">
          <div className="limitspreadsheet">
            {addingPeople && (
              <ReactDataSheet
                dataEditor={(props) => <CustomEditor {...props} />}
                data={isTeacher ? teacherGrid : studentGrid}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes) => {
                  const grid = (isTeacher ? teacherGrid : studentGrid).map(
                    (row) => [...row]
                  );
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });
                  if (isTeacher) {
                    setTeacherGrid(grid);
                  } else {
                    setStudentGrid(grid);
                  }
                }}
              />
            )}
          </div>
        </div>

        <Spacer height="2.5vmin" />
        <div
          onMouseEnter={() => setHoveringSubmit(true)}
          onMouseLeave={() => setHoveringSubmit(false)}
          onClick={async () => {
            if (!submitting && canSubmit !== "") {
              setSubmitting(true);
              const filtered = filterPeople();
              if (filtered.length > 0) {
                await insertProfiles(filterPeople(), realmUser);
                await refresh();
              }
              if (isTeacher) {
                setTeacherGrid(STARTING_TEACHER_GRID);
              } else {
                setStudentGrid(STARTING_STUDENT_GRID);
              }
              setSubmitting(false);
              setAddingPeople(false);
            }
          }}
          style={{
            color: "#fff",
            padding: "1vmin",
            fontSize: "3vmin",
            borderRadius: "5px",
            backgroundColor: "#4cc68d",
            filter: `grayscale(${canSubmit ? 0 : 1})`,
            cursor: hoveringSubmit && canSubmit ? "pointer" : "default",
          }}
        >
          Submit
        </div>
      </div>
    </div>
  );
};

const ClassesShowcase = ({
  refresh,
  isAdmin,
  district,
  onMobile,
  cleverUser,
  schoolData,
  userClasses,
  selectedSchool,
  setSelectedSchool,
  setSchoolData,
  user,
}) => {
  const [classes, setClasses] = useState([]);
  const [person, setPerson] = useState(false);
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [editingClass, setEditingClass] = useState();
  const [mouseLocation, setMouseLocation] = useState();
  const [creatingClass, setCreatingClass] = useState(false);
  const [settingAdmins, setSettingAdmins] = useState(false);
  const [saving, setSaving] = useState(false);
  const [hovering, setHovering] = useState(false);

  const [addingPeople, setAddingPeople] = useState();

  const { realmUser } = useContext(UserContext);

  const schoolsByName = schoolData?.filter((s) => s.name === selectedSchool);

  let school;

  if (schoolsByName.length === 1) {
    // If only one school with that name exists, choose that one
    school = schoolsByName[0];
  } else if (schoolsByName.length > 1) {
    // If there is more than one, filter by district as well
    school = schoolsByName.find((s) => s.district === district);
  }

  const usingClever = school && school.cleverId ? true : false;

  const [selectedStudents, setSS] = useState([]);
  const [selectedTeachers, setST] = useState([]);
  const [selectedClassName, setSCN] = useState("");

  const [teacherGrid, setTeacherGrid] = useState(
    JSON.parse(JSON.stringify(STARTING_TEACHER_GRID))
  );
  const [studentGrid, setStudentGrid] = useState(
    JSON.parse(JSON.stringify(STARTING_STUDENT_GRID))
  );

  const [adminGrid, setAdminGrid] = useState(
    JSON.parse(JSON.stringify(STARTING_ADMIN_GRID))
  );
  const [originalAdmins, setOriginalAdmins] = useState([]);
  const [originalTeachers, setOriginalTeachers] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  // when this function is called we know that selectedSchool is never
  // undefined
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const refreshData = async () => {
    await delay(1000); // Wait for 1 second
    //console.log(school);
    //console.log(selectedSchool);
    //console.log(schoolData);
    //console.log(district);

    // get all users
    let allUsers;
    if (school.cleverId) {
      allUsers = (await getCleverUsersFromSchool(school.cleverId, realmUser))
        .users;
    } else {
      // allUsers = await getNonCleverUsersFromSchool(
      //   school.name,
      //   school.classes,
      //   realmUser
      // );
      allUsers = await getUsersBySchoolAndDistrict(
        school.name,
        school.district,
        realmUser
      );
    }

    let c;
    //console.log(isAdmin);
    //console.log(cleverUser);
    if (isAdmin) {
      if (cleverUser) {
        c = (
          await loadAllClassesFromSchool(
            school.cleverId ?? school.name,
            realmUser
          )
        ).classes;

        c = c.concat(
          (
            await loadNonCleverClassesFromCleverSchool(
              school.cleverId,
              realmUser
            )
          ).classes
        );
        //console.log("clever user and admin", c);
      } else {
        c = (await loadAllClassesFromSchoolV2(school.name, district, realmUser))
          .classes;
        //console.log("other", c);
      }
    } else {
      if (cleverUser) {
        c = (await loadAllClassesFromUser(userClasses, realmUser)).classes;
      } else {
        c = (
          await loadAllClassesFromNonCleverUser(
            district,
            userClasses,
            realmUser
          )
        ).classes;
      }
    }
    //console.log(c);
    //console.log(allUsers);
    const students = allUsers.filter((u) => u.user === "student");
    const teachers = allUsers.filter((u) => u.user === "teacher");
    const admins = allUsers.filter((u) => u.user === "admin");

    for (let i = 0; i < 150; i++) {
      if (i < teachers.length) {
        teacherGrid[i + 1][1].value = teachers[i].email;
      } else {
        teacherGrid[i + 1][1].value = "";
      }
    }
    for (let i = 0; i < 1000; i++) {
      if (i < students.length) {
        studentGrid[i + 1][1].value = students[i].email;
      } else {
        studentGrid[i + 1][1].value = "";
      }
    }
    for (let i = 0; i < 20; i++) {
      if (i < admins.length) {
        adminGrid[i + 1][1].value = admins[i].email;
      } else {
        adminGrid[i + 1][1].value = "";
      }
    }
    setClasses(c);
    setAllStudents(students);
    setAllTeachers(teachers);
    setAllAdmins(admins);

    setOriginalStudents([...students]);
    setOriginalTeachers([...teachers]);
    setOriginalAdmins([...admins]);

    setStudentGrid(studentGrid);
    setTeacherGrid(teacherGrid);
    setAdminGrid(adminGrid);

    if (!localStorage.getItem("visitedClassShowcase")) {
      toast.info(
        "This is the class showcase. All teachers and students added from your classes will show under the boxes labeled 'All Teachers' and 'All Students'",
        { autoClose: 30000, closeOnClick: true }
      );
      localStorage.setItem("visitedClassShowcase", true);
    }
  };

  useEffect(() => {
    if (selectedSchool) {
      setLoading(true);
      refreshData().finally(() => setLoading(false));
    }
  }, [selectedSchool]);

  // useEffect(() => {
  //   const handleMove = (e) => {
  //     setMouseLocation({
  //       x: e.clientX,
  //       y: e.clientY,
  //     });
  //   };
  //   window.addEventListener("mousemove", handleMove);
  //   return () => window.removeEventListener("mousemove", handleMove);
  // }, []);
  async function addUser(attributes) {
    let nAttributes = attributes;
    const profile = await loadProfile(attributes.email, realmUser);
    if (profile.user === null) {
      //console.log("inserting user ", attributes);
      await insertProfiles([attributes], realmUser);
      return;
    } else if (
      attributes.classes !== undefined &&
      attributes.classes !== null
    ) {
      //Classes are added to a list, other attributes simply overwrite
      let nClasses = [...Set(profile.user.class.concat(attributes.classes))];
      nAttributes.classes = nClasses;
    }
    updateProfile(attributes.email, realmUser, attributes);
  }

  const processNewEntries = async (
    newEntries,
    role,
    className,
    classesLocal,
    realmUser
  ) => {
    const classNames = Object.values(classesLocal).map((cls) => cls.name);

    for (const email of newEntries) {
      const newClassName = `${email}'s class`;
      let userDoc = (await loadProfile(email, realmUser)).user;

      if (userDoc === null) {
        userDoc = {
          email,
          user: role,
          district: school.district,
          count: 0,
          class: [],
          school: school.name,
          wellnesstimer: "30 seconds",
        };
      } else {
        userDoc.user = role;
        userDoc.district = school.district;
        userDoc.school = school.name;
      }

      if (role === "student" && className) {
        // if they are a student and we are adding them to a specific class add the class
        // to the teachers list and student list of classes
        let teachersClass = classesLocal.find((cls) => cls.name === className);
        if (!teachersClass.students.includes(email))
          teachersClass.students.push(email);
        if (!userDoc.class.includes(className)) userDoc.class.push(className);
      } else if (role === "teacher") {
        if (className) {
          if (!userDoc.class.includes(className)) userDoc.class.push(className);
        } else {
          let teachersClass = classesLocal.find(
            (cls) => cls.name === newClassName
          );
          if (!userDoc.class.includes(newClassName))
            userDoc.class.push(newClassName);

          // if the class doesnt exist create it and insert it
          if (!teachersClass) {
            teachersClass = {
              teachers: [email],
              students: [],
              name: newClassName,
              school: school.name,
              district: school.district,
            };

            await insertClass(teachersClass, realmUser);
            classNames.push(newClassName);
            classesLocal.push(teachersClass);
          } else if (!teachersClass.teachers.includes(email)) {
            // if the class exists update the teachers
            teachersClass.teachers.push(email);
            await updateClassByID(teachersClass._id, newClassName, realmUser, {
              teachers: teachersClass.teachers,
            });
          }
        }
      }
      await addUser(userDoc);
    }

    return classNames;
  };

  const removeDataFromAllClasses = async (
    entriesToRemove,
    role,
    classesLocal,
    realmUser
  ) => {
    const key = role === "teacher" ? "teachers" : "students";

    for (const classObj of classesLocal) {
      classObj[key] = classObj[key].filter(
        (member) => !entriesToRemove.some((entry) => entry.email === member)
      );

      await updateClassByID(classObj._id, classObj.name, realmUser, {
        [key]: classObj[key],
      });
    }
  };

  const updateSchoolData = async (role, classNames, emailsToAdd, realmUser) => {
    if (role === "teacher") {
      await updateSchool(school.name, school.district, realmUser, {
        classes: classNames,
      });
    } else if (role === "admin") {
      await updateSchool(school.name, school.district, realmUser, {
        admins: emailsToAdd,
      });
    }
  };

  const updateStudentClass = async (
    className,
    emailsToAdd,
    classesLocal,
    realmUser
  ) => {
    let teachersClass = classesLocal.find((cls) => cls.name === className);
    teachersClass.students = emailsToAdd;
    await updateClassByID(teachersClass._id, className, realmUser, {
      students: emailsToAdd,
    });
  };

  const updateTeacherClass = async (className, emailsToAdd, classesLocal) => {
    // if (role === "teacher" && className) {
    let teachers_class = classesLocal.find((cls) => cls.name === className);
    await updateClassByID(teachers_class._id, className, realmUser, {
      teachers: emailsToAdd,
    });
    teachers_class.teachers = emailsToAdd;
  };
  // };

  const processEntriesToRemove = async (entriesToRemove, realmUser) => {
    for (const user of entriesToRemove) {
      let email = typeof user === "string" ? user : user.email;
      await updateProfile(email, realmUser, {
        district: "",
        school: "",
        user: "student",
        class: [],
        schools: undefined,
      });
    }
  };

  function processEntries(emailsToAdd, originalList) {
    // Find new entries that are not in the original list
    let newEntries = emailsToAdd.filter(
      (email) => !originalList.some((user) => user.email === email)
    );

    // Find entries to remove that are not in emailsToAdd
    let entriesToRemove = originalList.filter(
      (user) => !emailsToAdd.includes(user.email)
    );

    // Find common elements between newEntries and entriesToRemove
    const commonElements = newEntries.filter((email) =>
      entriesToRemove.some((user) => user.email === email)
    );

    // Remove common elements from newEntries and entriesToRemove
    newEntries = newEntries.filter((email) => !commonElements.includes(email));
    entriesToRemove = entriesToRemove.filter(
      (user) => !commonElements.includes(user.email)
    );

    return { newEntries, entriesToRemove };
  }

  const saveChanges = async (
    role,
    grid,
    originalList,
    setOriginalList,
    className = undefined,
    waitToRefresh = false
  ) => {
    // setLoading(true);
    setSaving(true);

    let classesLocal = classes;

    const emailsToAdd = grid
      .slice(1)
      .map((row) => row[1].value)
      .filter((email) => email !== "");

    const { newEntries, entriesToRemove } = processEntries(
      emailsToAdd,
      originalList
    );
    //console.log("entries to remove ", entriesToRemove);
    //console.log("new entries ", newEntries);
    //console.log("emails to add ", emailsToAdd);
    //console.log("original list", originalList);

    //console.log("Process new entires");
    const classNames = await processNewEntries(
      newEntries,
      role,
      className,
      classesLocal,
      realmUser
    );

    //console.log("remove data from all classes");
    if (
      (role === "teacher" || role === "student") &&
      entriesToRemove.length > 0
    ) {
      await removeDataFromAllClasses(
        entriesToRemove,
        role,
        classesLocal,
        realmUser
      );
    }

    //console.log("update school data");
    await updateSchoolData(role, classNames, emailsToAdd, realmUser);

    // //console.log("update student class")

    if (role === "student" && className) {
      await updateStudentClass(className, emailsToAdd, classesLocal, realmUser);
    }

    if (role === "teacher" && className) {
      await updateTeacherClass(className, emailsToAdd, classesLocal, realmUser);
    }

    //console.log("process entries to be removed");
    //console.log(entriesToRemove, originalList);

    await processEntriesToRemove(entriesToRemove, realmUser);
    //console.log(schoolData);
    // update school data
    setClasses(classesLocal);
    setOriginalList(emailsToAdd.map((email) => ({ email, user: role })));
    // setLoading(false);
    if (!waitToRefresh) {
      await refreshData();
    }
    setSaving(false);
    // window.location.reload();
  };

  const handleCellsChanged = (changes) => {
    const grid = studentGrid.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      // Only allow changes to empty cells
      if (grid[row][col].value === "") {
        grid[row][col] = { ...grid[row][col], value };
      }
    });
    setStudentGrid(grid);
  };
  // const saveChanges = async (
  //   role,
  //   grid,
  //   originalList,
  //   setOriginalList,
  //   className = undefined
  // ) => {
  //   let classesLocal = classes;
  //   const emailsToAdd = grid
  //     .slice(1)
  //     .map((row) => row[1].value)
  //     .filter((email) => email !== "");
  //   let newEntries = emailsToAdd.filter(
  //     (email) => !originalList.some((user) => user.email === email)
  //   );
  //   let entriesToRemove = originalList.filter(
  //     (user) => !emailsToAdd.includes(user.email)
  //   );

  //   const commonElements = newEntries.filter((email) =>
  //     entriesToRemove.includes(email)
  //   );

  //   // Remove common elements from newEntries and entriesToRemove
  //   newEntries = newEntries.filter((email) => !commonElements.includes(email));
  //   entriesToRemove = entriesToRemove.filter(
  //     (email) => !commonElements.includes(email)
  //   );
  //   // //console.log(newEntries);
  //   // //console.log(entriesToRemove);
  //   // //console.log(emailsToAdd);
  //   const classNames = Object.values(classes).map((cls) => cls.name);

  //   for (const email of newEntries) {
  //     const new_class_name = `${email}'s class`;
  //     // classes is conditional on the user type if teacher reset it to only include their class
  //     // if student
  //     // let classes = role === "teacher" ? [] : []

  //     // add user if they do not exist
  //     //console.log(school.district);
  //     // - [ ] When adding a teacher to a class dont reset their classes
  //     // trying to load user profile first but not working figure out trmw
  //     //console.log(email);
  //     let userDoc = (await loadProfile(email, realmUser)).user;
  //     //console.log(userDoc);
  //     if (userDoc === null) {
  //       userDoc = {
  //         email: email,
  //         user: role,
  //         district: school.district,
  //         count: 0,
  //         class: [],
  //         school: school.name,
  //         wellnesstimer: "30 seconds",
  //       };
  //     } else {
  //       userDoc.user = role;
  //       userDoc.district = school.district;
  //       userDoc.school = school.name;
  //     }

  //     // TODO FINISH THIS need to check how students are getting pulled in
  //     // Loop through all classes and get all students right now not getting updated
  //     // //console.log(selectedClassName)

  //     if (role === "student" && className) {
  //       // when adding student need to update their personal class
  //       // add them to the class that they are being added too student
  //       let teachers_class = classes.find((cls) => cls.name === className);
  //       if (!teachers_class.students.includes(email)) {
  //         if (!userDoc.class.includes(className)) userDoc.class.push(className);
  //         teachers_class.students.push(email);
  //         //console.log(teachers_class);
  //         // add student to this class
  //         //not sure where this is used need to track down the students current class list and add this class to it?
  //         // await updateClassByID(teachers_class._id, className, realmUser, {
  //         //   students: teachers_class.students,
  //         // });
  //         // setClasses((prevClasses) => [...prevClasses, teachers_class]);
  //       }
  //     }
  //     if (role === "teacher" && className) {
  //       // let teachers_class = classes.find((cls) => cls.name === className);
  //       if (!userDoc.class.includes(className)) userDoc.class.push(className);
  //     } else if (role === "teacher") {
  //       // if we are adding a teacher in all teachers, add the class to their class object,
  //       // create a class in their name, add them as a teacher of that class,
  //       // add the class in the list of classes in a school
  //       let teachers_class = classes.find((cls) => cls.name === new_class_name);
  //       //console.log(teachers_class);
  //       if (!userDoc.class.includes(new_class_name))
  //         userDoc.class.push(new_class_name);

  //       if (!teachers_class) {
  //         teachers_class = {
  //           teachers: [email],
  //           students: [],
  //           name: new_class_name,
  //           school: school.name,
  //           district: school.district,
  //         };

  //         await insertClass(teachers_class, realmUser);

  //         classNames.push(new_class_name);
  //         classesLocal.push(teachers_class);
  //         //console.log(teachers_class);
  //       } else if (!teachers_class.teachers.includes(email)) {
  //         teachers_class.teachers.push(email);
  //         await updateClassByID(teachers_class._id, new_class_name, realmUser, {
  //           teachers: teachers_class.teachers,
  //         });
  //         // setClasses((prevClasses) => [...prevClasses, teachers_class]);
  //         // //console.log(teachers_class);
  //       }
  //       // setClasses((prevClasses) => [...prevClasses, teachers_class]);
  //     }
  //     await addUser(userDoc);
  //   }
  //   // if deleting a teacher or student we need to delete their name from all teachers/students lists
  //   if ((role === "teacher" || role === "student") && entriesToRemove.length > 0) {
  //     for (const classObj of classesLocal) {
  //       const key = role === "teacher" ? "teachers" : "students";

  //       classObj[key] = classObj[key].filter(
  //         (member) => !entriesToRemove.some((entry) => entry.email === member)
  //       );

  //       await updateClassByID(classObj._id, className, realmUser, {
  //         [key]: classObj[key],
  //       });
  //     }
  //   }

  //   if (role === "teacher") {
  //     await updateSchool(school.name, school.district, realmUser, {
  //       classes: classNames,
  //     });
  //   }
  //   // if (role === "teacher" && className) {
  //   //   let teachers_class = classes.find((cls) => cls.name === className);
  //   //   await updateClassByID(teachers_class._id, className, realmUser, {
  //   //     teachers: emailsToAdd,
  //   //   });

  //   //   teachers_class.teachers = emailsToAdd;
  //   //   setClasses((prevClasses) => {
  //   //     return prevClasses.map((classItem) =>
  //   //       classItem.id === teachers_class.id ? teachers_class : classItem
  //   //     );
  //   //   });

  //   //   // setClasses((prevClasses) => [...prevClasses, teachers_class]);
  //   // }
  //   // delete the user account and reset them to student
  //   for (const user of entriesToRemove) {
  //     let email = typeof user === "string" ? user : user.email;
  //     await updateProfile(email, realmUser, {
  //       district: "",
  //       school: "",
  //       user: "student",
  //       class: [],
  //     });
  //   }
  //   // update the admins list for the school
  //   if (role === "admin") {
  //     await updateSchool(school.name, school.district, realmUser, {
  //       admins: emailsToAdd,
  //     });
  //     // setAllAdmins(emailsToAdd)
  //   }
  //   // create the All Teachers class or update it

  //   if (role === "student" && className) {
  //     let teachers_class = classesLocal.find((cls) => cls.name === className);
  //     teachers_class.students = emailsToAdd;
  //     await updateClassByID(teachers_class._id, className, realmUser, {
  //       students: emailsToAdd,
  //     });
  //     setClasses((prevClasses) => {
  //       return prevClasses.map((classItem) =>
  //         classItem.id === teachers_class.id ? teachers_class : classItem
  //       );
  //     });
  //   }
  //   // await refreshData();
  //   // await refresh()
  //   setOriginalList(emailsToAdd.map((email) => ({ email, user: role })));
  // };

  if (loading || syncing || saving) {
    return (
      <LoadingOverlay></LoadingOverlay>
      // <>

      //   <CircularProgress
      //     style={{ color: school && school.cleverId ? "#1464FF" : "#4cc68d" }}
      //   />
      //   {syncing && (
      //     <p
      //       style={{
      //         width: "60%",
      //         color: "#1464FF",
      //         fontSize: "4vmin",
      //         textAlign: "center",
      //       }}
      //     >
      //       We're syncing your data with Clever. This may take a few minutes!
      //     </p>
      //   )}
      // </>
    );
  }

  return (
    <div id="ClassShowcase">
      <Spacer height="7.5vmin" />

      <p className="SSV2Title2" style={{ color: "#000", fontSize: "3.5vmin" }}>
        Create or select a class to manage your classes
      </p>
      {(user === "123wellness" || user === "superadmin") && (
        <p
          // className="SSV2Title"
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={() => setSelectedSchool(undefined)}
          style={{
            width: "30vw",
            fontSize: "2.5vmin",
            fontWeight: "bold",
            textAlign: "center",
            cursor: hovering ? "pointer" : "default",
            color: "#4cc68d",

            // color: selected || hovering ? "#fff" : highlightedColor,
          }}
        >
          Click here to view a different school
        </p>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {!usingClever && (
          <>
            {user !== "teacher" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="SSV2ClassPreview"
                  style={{
                    backgroundColor:
                      school && school.cleverId ? "#1464FF" : "#4cc68d",
                  }}
                >
                  <p className="SSV2CPTitle">
                    All Admins{school && ` at ${school.name}`}
                  </p>
                  <Spacer height="2vmin" />
                  <div className="SSV2Backdrop">
                    <ReactDataSheet
                      dataEditor={(props) => <CustomEditor {...props} />}
                      data={adminGrid}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={(changes) => {
                        const grid = adminGrid.map((row) => [...row]);
                        changes.forEach(({ cell, row, col, value }) => {
                          grid[row][col] = { ...grid[row][col], value };
                        });
                        setAdminGrid(grid);
                      }}
                    />
                  </div>
                  {isAdmin && (
                    <p
                      onClick={async () => {
                        if (!saving) {
                          setSaving(true);
                          await saveChanges(
                            "admin",
                            adminGrid,
                            originalAdmins,
                            setOriginalAdmins
                          );
                          setSaving(false);
                        }
                      }}
                      style={{
                        textAlign: "center",
                        color: "white",
                        marginTop: "2vmin",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderColor: "white",
                        padding: "1vmin",
                        borderWidth: "2.5px",
                        fontSize: "2vmin",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      Save Admins
                    </p>
                  )}
                </div>

                {/* all teachers tab */}
                <div
                  className="SSV2ClassPreview"
                  style={{
                    backgroundColor:
                      school && school.cleverId ? "#1464FF" : "#4cc68d",
                  }}
                >
                  <p className="SSV2CPTitle">
                    All Teachers{school && ` at ${school.name}`}
                  </p>
                  <Spacer height="2vmin" />
                  <div className="SSV2Backdrop">
                    <ReactDataSheet
                      dataEditor={(props) => <CustomEditor {...props} />}
                      data={teacherGrid}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={(changes) => {
                        const grid = teacherGrid.map((row) => [...row]);
                        changes.forEach(({ cell, row, col, value }) => {
                          grid[row][col] = { ...grid[row][col], value };
                        });
                        setTeacherGrid(grid);
                      }}
                    />
                    {/* {allTeachers.map((student, i) => (
                  <PersonBubble
                    key={i}
                    data={student}
                    onMobile={onMobile}
                    setPerson={setPerson}
                    color={school && school.cleverId ? "#1464FF" : "#4cc68d"}
                  />
                ))} */}
                  </div>
                  {(isAdmin || user === "teacher") && (
                    <p
                      onClick={async () => {
                        if (!saving) {
                          setSaving(true);
                          await saveChanges(
                            "teacher",
                            teacherGrid,
                            originalTeachers,
                            setOriginalTeachers
                          );
                          setSaving(false);
                        }
                      }}
                      style={{
                        textAlign: "center",
                        color: "white",
                        marginTop: "2vmin",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderColor: "white",
                        padding: "1vmin",
                        borderWidth: "2.5px",
                        fontSize: "2vmin",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      Save Teachers
                    </p>
                  )}
                  {/* {!usingClever && (
              <>
                <Spacer height="2vmin" />
                <div className="AddIconContainer">
                  <AddIcon
                    onClick={() => setAddingPeople("teacher")}
                    style={{
                      alignSelf: "center",
                      fontSize: onMobile ? "7.5vmin" : "4vmin",
                      color: school && school.cleverId ? "#1464FF" : "#4cc68d",
                    }}
                  />
                </div>
                <Spacer height={onMobile ? "2vmin" : "0.5vmin"} />
                <p
                  style={{
                    margin: 0,
                    alignSelf: "center",
                    color: "#fff",
                    fontSize: "2vmin",
                  }}
                >
                  Add Teachers
                </p>
              </>
            )} */}
                </div>

                {/* all students tab */}
                <div
                  className="SSV2ClassPreview"
                  style={{
                    backgroundColor:
                      school && school.cleverId ? "#1464FF" : "#4cc68d",
                  }}
                >
                  <p className="SSV2CPTitle">
                    All Students{school && ` at ${school.name}`}
                  </p>
                  <Spacer height="2vmin" />
                  {/* {allStudents.length > 0 && ( */}
                  <div className="SSV2Backdrop">
                    <ReactDataSheet
                      dataEditor={(props) => <CustomEditor {...props} />}
                      data={studentGrid}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={handleCellsChanged}
                    />

                    {/* {allStudents.map((student, i) => (
                  <PersonBubble
                    key={i}
                    data={student}
                    onMobile={onMobile}
                    setPerson={setPerson}
                    color={school && school.cleverId ? "#1464FF" : "#4cc68d"}
                  />
                ))} */}
                  </div>
                  <p
                    onClick={async () => {
                      if (!saving) {
                        setSaving(true);
                        await saveChanges(
                          "student",
                          studentGrid,
                          originalStudents,
                          setOriginalStudents
                        );
                        setSaving(false);
                      }
                    }}
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "2vmin",
                      borderRadius: "5px",
                      borderStyle: "solid",
                      borderColor: "white",
                      padding: "1vmin",
                      borderWidth: "2.5px",
                      fontSize: "2vmin",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Add Students
                  </p>
                  {/* )} */}
                  {/* {!usingClever && (
              <>
                <Spacer height="2vmin" />
                <div
                  className="AddIconContainer"
                  onClick={() => setAddingPeople("student")}
                >
                  <AddIcon
                    style={{
                      alignSelf: "center",
                      fontSize: onMobile ? "7.5vmin" : "4vmin",
                      color: school && school.cleverId ? "#1464FF" : "#4cc68d",
                    }}
                  />
                </div>
                <Spacer height={onMobile ? "2vmin" : "0.5vmin"} />
                <p
                  style={{
                    margin: 0,
                    alignSelf: "center",
                    color: "#fff",
                    fontSize: "2vmin",
                  }}
                >
                  Add Students
                </p>
              </>
            )} */}
                </div>
              </div>
            )}
            <Spacer height="1vmin" />
            {/* <div
            onClick={async () => {
              if (!saving) {
                setSaving(true);
                const students = studentGrid.slice(1).map((s) => s[1].value);
                const teachers = teacherGrid.slice(1).map((t) => t[1].value);
                setSaving(false);
              }
            }}
            style={{
              cursor: "pointer",
              padding: "2vmin",
              backgroundColor: "#4cc68d",
              borderRadius: "5px",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "3vmin",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              Save Changes
            </p>
          </div> */}
          </>
        )}
        <Spacer height="7.5vmin" />
        {classes
          .filter(
            (c) =>
              c != undefined &&
              c.name !== "All Teachers" &&
              (!c.school || c.school === selectedSchool)
          )
          .map((c, i) => (
            <ClassPreview
              key={i}
              classData={c}
              onMobile={onMobile}
              setTeachers={setST}
              setStudents={setSS}
              setPerson={setPerson}
              setClassName={setSCN}
              allStudents={allStudents}
              allTeachers={allTeachers}
              refreshData={refreshData}
              setEditingClass={setEditingClass}
              saveChanges={saveChanges}
            />
          ))}
      </div>
      <Spacer height="5vmin" />
      {!cleverUser && (
        <>
          <AddClassButton setCreatingClass={setCreatingClass} />

          <CreateClassScreen
            school={school}
            onMobile={onMobile}
            setStudents={setSS}
            setTeachers={setST}
            setPerson={setPerson}
            setClassName={setSCN}
            allStudents={allStudents}
            allTeachers={allTeachers}
            refreshData={async () => {
              await refresh();
              await refreshData();
              toast.info(
                "Please refresh to see the roster in your newly-added school.",
                {
                  closeOnClick: true,
                }
              );
            }}
            students={selectedStudents}
            teachers={selectedTeachers}
            editingClass={editingClass}
            isCleverSchool={usingClever}
            creatingClass={creatingClass}
            className={selectedClassName}
            setEditingClass={setEditingClass}
            setCreatingClass={setCreatingClass}
          />

          <ToolTip
            person={person}
            onMobile={onMobile}
            mouseLocation={mouseLocation}
            color={creatingClass ? "#4cc68d" : "#1464ff"}
          />
          <AddPeople
            school={school}
            onMobile={onMobile}
            refresh={refreshData}
            allStudents={allStudents}
            allTeachers={allTeachers}
            addingPeople={addingPeople}
            setAddingPeople={setAddingPeople}
          />
        </>
      )}
      {/* {usingClever && (
        <SyncWithCleverButton
          {...{
            syncing,
            realmUser,
            setSyncing,
            refreshData,
            districtId: school.district,
          }}
        />
      )} */}
    </div>
  );
};

const DistrictButton = ({
  data,
  onMobile,
  selectedDistrict,
  setSelectedDistrict,
  setViewingCleverDistrict,
  setViewedDistrict,
  user,
}) => {
  const [hovering, setHovering] = useState(false);
  const selected = selectedDistrict && selectedDistrict.name === data.name;
  const highlightedColor = !data.cleverId ? "#4cc68d" : "#1464ff";
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => setSelectedDistrict(selected ? undefined : data)}
      style={{
        padding: "2vmin",
        textAlign: "center",
        borderRadius: "5px",
        borderWidth: "2.5px",
        borderStyle: "solid",
        marginBottom: "2.5vmin",
        borderColor: highlightedColor,
        transition: "background-color 0.5s",
        cursor: hovering ? "pointer" : "default",
        backgroundColor: selected || hovering ? highlightedColor : "#fff",
      }}
    >
      <p
        style={{
          margin: 0,
          width: "25vw",
          fontSize: "3vmin",
          fontWeight: "bold",
          textAlign: "center",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {data.name}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {!data.schools || data.schools.length === 0
          ? "No schools"
          : `${ data.schools.length} school${
              data.schools.length === 1 ? "" : "s"
            }`}
      </p>
      <Spacer height="2vmin" />
      <p
        style={{
          margin: 0,
          fontSize: "3vmin",
          color: selected || hovering ? "#fff" : highlightedColor,
        }}
      >
        {data.superadmins.length === 0
          ? "No superadmins"
          : `${data.superadmins.length} superadmin${
              data.superadmins.length === 1 ? "" : "s"
            }`}
      </p>
      <Spacer height="2vmin" />
      {user.user.user === "123wellness" && 
        // NOTE THIS MAY NOT WORK FOR NON CLEVER NOT SURE HOW TO TEST THIS WELL
        // data.cleverId && 
        <button style={{
          cursor: 'pointer',
          backgroundColor: 'white',
          color: ` ${data.cleverId ? 'rgb(20, 100, 255)' : 'rgb(76, 198, 141)'}`,
          padding: '1vmin 3vmin',
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '2.5vmin',
          margin: '1vmin',
          borderRadius: '2vmin',
          border: `0.3vmin solid ${data.cleverId ? 'rgb(20, 100, 255)' : 'rgb(76, 198, 141)'}`,
          transition: '0.3s',
          fontWeight: 'bold',
          minWidth: '20vmin',
          minHeight: '7vmin',          
        }}
        onClick={() => {setViewedDistrict(data.cleverId || data.name); setViewingCleverDistrict(data.cleverId ? true : false)}}
        >
          View District
          </button>
      }
      {selected && (
        <p
          style={{
            color: highlightedColor,
            fontWeight: "bold",
            paddingTop: "2vmin",
            position: "absolute",
            marginTop: "2.5px",
            marginBottom: 0,
            fontSize: "2.5vmin",
            // alignSelf: "center",

            transform: `translate(${onMobile ? "-8vmin" : "-2vmin"} )`,
          }}
        >
          Click here to view more districts
        </p>
      )}
    </div>
  );
};

const AddDistrictButton = ({ refresh, onMobile, realmUser }) => {
  // TODO: move this style somewhere else i wrote this with speed as
  // a priority
  const [inputVal, setInputVal] = useState("");
  const [hovering, setHovering] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addingDistrict, setAddingDistrict] = useState(false);
  const [adminGrid, setAdminGrid] = useState(STARTING_SUPER_ADMIN_GRID);

  // async function gridToPeople() {
  //   const peopleToAdd = [];
  //   const people = adminGrid.slice(1);
  //   for (const item of people) {
  //     if (item[1].value !== "") {
  //       peopleToAdd.push(item[1].value);
  //     }
  //     // let userDoc = {
  //     //   email:item[1].value,
  //     //   // will need to make them whatever user tpe is inputted
  //     //   user:"superadmin",
  //     //   // class:[chosenClass],
  //     //   district: selectedDistrict.name,
  //     //   // school:chosenSchool,
  //     //   count:0,
  //     //   dataUse:false,
  //     //   wellnesstimer:"30 seconds"
  //     // }
  //     // addUser(userDoc)
  //   }
  //   return peopleToAdd;
  // }
  async function addUser(attributes) {
    let nAttributes = attributes;
    const profile = await loadProfile(attributes.email, realmUser);
    if (profile.user === null) {
      //console.log("inserting user ", attributes);
      await insertProfiles([attributes], realmUser);
      return;
    } else if (
      attributes.classes !== undefined &&
      attributes.classes !== null
    ) {
      //Classes are added to a list, other attributes simply overwrite
      let nClasses = [...Set(profile.user.class.concat(attributes.classes))];
      nAttributes.classes = nClasses;
    }
    updateProfile(attributes.email, realmUser, attributes);
  }

  async function gridToPeople() {
    const peopleToAdd = [];
    const people = adminGrid.slice(1);
    for (const item of people) {
      if (item[1].value !== "") {
        peopleToAdd.push(item[1].value);
        let userDoc = {
          email: item[1].value,
          user: "superadmin",
          district: inputVal,
          count: 0,
          dataUse: false,
          class: [],
          school: "",
          wellnesstimer: "30 seconds",
        };
        await addUser(userDoc);
      }
    }
    return peopleToAdd;
  }

  if (submitting) return <LoadingOverlay></LoadingOverlay>;
  return (
    <>
      <p
        onClick={() => setAddingDistrict(true)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          margin: 0,
          padding: "1vmin",
          cursor: "pointer",
          fontSize: "3vmin",
          fontWeight: "bold",
          borderRadius: "5px",
          borderWidth: "2.5px",
          borderStyle: "solid",
          borderColor: "#4cc68d",
          color: hovering ? "#FFF" : "#4cc68d",
          backgroundColor: hovering ? "#4cc68d" : "#FFF",
          transition: "color 0.25s, background-color 0.25s",
        }}
      >
        Create a new district
      </p>
      <Spacer height={"4vmin"} />

      {/* 
        This is a part of the screen, but doesn't actually take up space.
        TODO: add styling to buttons and stuff
      */}
      <div
        style={{
          top: 0,
          left: 0,
          height: "100vh",
          position: "fixed",
          transition: "width 0.5s",
          backgroundColor: "#4cc68d",
          zIndex: addingDistrict ? 2 : -1,
          width: addingDistrict ? "100vw" : "0vw",

          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {submitting && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "#00000050",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ height: "60px", width: "60px" }} />
          </div>
        )} */}
        <p
          onClick={() => {
            setAddingDistrict(false);
          }}
          style={{
            margin: 0,
            top: "2vmin",
            left: "2vmin",
            color: "#FFF",
            padding: "1vmin",
            cursor: "pointer",
            borderRadius: "5px",
            borderColor: "#FFF",
            borderWidth: "2.5px",
            borderStyle: "solid",
            position: "absolute",
          }}
        >
          Go Back
        </p>
        <p
          className="SSV2Title"
          style={{
            alignSelf: "center",
            color: "#FFF",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
        >
          District Name
        </p>
        <input
          type="text"
          style={{
            width: "30vmax",
            color: "#4cc68d",
            padding: "2vmin",
            fontSize: "2vmin",
            fontWeight: "bold",
            alignSelf: "center",
            borderRadius: "5px",
            textAlign: "center",
            borderColor: "#4cc68d",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
          onChange={(e) => setInputVal(e.target.value)}
        />
        <Spacer height="5vmin" />
        <p
          className="SSV2Title"
          style={{
            alignSelf: "center",
            color: "#FFF",
            marginBottom: "0",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
        >
          Superadmins (emails only)
        </p>
        <div
          className="spreadsheetrow"
          style={{
            alignSelf: "center",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
        >
          <div
            className="limitspreadsheet"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <ReactDataSheet
              dataEditor={(props) => <CustomEditor {...props} />}
              data={adminGrid}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={(changes) => {
                const grid = adminGrid.map((row) => [...row]);
                changes.forEach(({ cell, row, col, value }) => {
                  grid[row][col] = { ...grid[row][col], value };
                });
                setAdminGrid(grid);
              }}
            />
          </div>
        </div>
        <Spacer height="5vmin" />
        <p
          onClick={async () => {
            if (!submitting && inputVal !== "") {
              setSubmitting(true);
              const emails = await gridToPeople();
              await insertDistrict(
                { schools: [], superadmins: emails, name: inputVal },
                realmUser
              );
              await refresh();
              setAddingDistrict(false);
              setSubmitting(false);
            }
          }}
          style={{
            cursor: "pointer",
            padding: "1vmin",
            fontSize: "3vmin",
            borderStyle: "solid",
            borderRadius: "5px",
            borderWidth: "2.5px",
            borderColor: "#FFF",
            alignSelf: "center",
            color: "#FFF",
            fontWeight: "bold",
            transitionDelay: "1s",
            transition: "opacity 0.5s",
            opacity: addingDistrict ? 1 : 0,
          }}
        >
          Create District
        </p>
      </div>
    </>
  );
};

export default function StudentsScreenV2({ user, goBack, disableMargin }) {
  const [onMobile, setOnMobile] = useState();
  const [districtData, setDistrictData] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [viewedDistrict, setViewedDistrict] = useState();
  const [viewingCleverDistrict, setViewingCleverDistrict] = useState();
  const [schoolData, setSchoolData] = useState();
  const [selectedSchool, setSelectedSchool] = useState();
  const { realmUser } = useContext(UserContext);
  const schoolNames = user.user.cleverId
    ? user.user.schools
    : user.user.schools ?? [user.user.school];

  const [settingAdmins, updateSettingAdmins] = useState(false);
  const [superAdminGrid, setSuperAdminGrid] = useState(
    STARTING_SUPER_ADMIN_GRID
  );
  const [originalSuperadmins, setOriginalSuperadmins] = useState([]);

  async function addUser(attributes) {
    let nAttributes = attributes;
    const profile = await loadProfile(attributes.email, realmUser);
    if (profile.user === null) {
      //console.log("inserting user ", attributes);
      insertProfiles([attributes], realmUser);
      return;
    } else if (
      attributes.classes !== undefined &&
      attributes.classes !== null
    ) {
      //Classes are added to a list, other attributes simply overwrite
      let nClasses = [...Set(profile.user.class.concat(attributes.classes))];
      nAttributes.classes = nClasses;
    }
    updateProfile(attributes.email, realmUser, attributes);
  }

  async function gridToPeople() {
    const oldSuperadmins = originalSuperadmins;
    //console.log("old admins: ", oldSuperadmins);
    const peopleToAdd = [];
    const people = superAdminGrid.slice(1);
    //console.log("district data: ", districtData);
    //console.log("school data : ", schoolData);
    //console.log("selected district: ", selectedDistrict);
    //console.log("selected School: ", selectedSchool);

    for (const item of people) {
      if (item[1].value !== "") {
        peopleToAdd.push(item[1].value);
      }
    }

    // Find new superadmins to add
    const newSuperadmins = peopleToAdd.filter(
      (email) => !oldSuperadmins.includes(email)
    );
    //console.log("new admins: ", newSuperadmins);

    // Find superadmins to remove (change to student)
    const superadminsToRemove = oldSuperadmins.filter(
      (email) => !peopleToAdd.includes(email)
    );

    // Add new superadmins
    for (const email of newSuperadmins) {
      let userDoc = {
        email: email,
        user: "superadmin",
        district: selectedDistrict.name,
        count: 0,
        dataUse: false,
        class: [],
        school: "",
        wellnesstimer: "30 seconds",
      };
      await addUser(userDoc);
    }
    // Change removed superadmins to students
    for (const email of superadminsToRemove) {
      // Assuming you have a function to update user role
      await updateProfile(email, realmUser, {
        district: "",
        school: "",
        user: "student",
      });
    }

    return peopleToAdd;
  }

  useEffect(() => {
    if (selectedDistrict === undefined) {
      setSuperAdminGrid(STARTING_SUPER_ADMIN_GRID);
      setOriginalSuperadmins([]);
    } else {
      const tgrid = JSON.parse(JSON.stringify(STARTING_SUPER_ADMIN_GRID)); // Create a deep copy
      for (let i = 0; i < selectedDistrict.superadmins.length; i++) {
        if (i + 1 < tgrid.length) {
          tgrid[i + 1][1] = { value: selectedDistrict.superadmins[i] };
        } else {
          tgrid.push([
            { value: i + 1 },
            { value: selectedDistrict.superadmins[i] },
          ]);
        }
      }
      // Trim any excess rows
      tgrid.length = Math.max(
        selectedDistrict.superadmins.length + 1,
        STARTING_ADMIN_GRID.length
      );
      setSuperAdminGrid(tgrid);
      setOriginalSuperadmins([...selectedDistrict.superadmins]);
    }
  }, [selectedDistrict]);

  // adding admin to a school does not work
  const refresh = async () => {
    let result;
    if (user.user.user === "superadmin") {
      result = await fetchAllSchoolsInDistrict(user.user.district, realmUser);
    } else if (user.user.user === "123wellness") {
      setDistrictData((await loadDistricts(realmUser)).districts);
      const schoolData = await loadSchools2({}, realmUser);
      result = schoolData.schools;
      // const response = await fetchAllInformationFor123WellnessUser(realmUser);
      // result = response.schools;
      // setDistrictData(response.districts);
      // result = (await fetchAllInformationFor123WellnessUser(realmUser)).schools;
      // setDistrictData()
    } else if (user.user.cleverId) {
      result = await fetchSchoolsInfoByCleverId(schoolNames, realmUser);
    } else {
      result = await fetchSchoolsInfoByName(
        user.user.district,
        schoolNames,
        realmUser
      );
    }

    setSchoolData(result);
    if (!localStorage.getItem("visitedSchoolsScreen")) {
      toast.info("Each of the buttons below represents a school.", {
        closeOnClick: true,
      });
      toast.info(
        "Click on one to view class info, or click the Create School button to make a custom school!",
        { closeOnClick: true }
      );
      localStorage.setItem("visitedSchoolsScreen", true);
    }
  };

  useEffect(() => {
    // localStorage.removeItem("visitedSchoolsScreen");
    if (selectedSchool && selectedDistrict) {
      const keys = [
        selectedSchool._id,
        selectedSchool.name,
        selectedSchool.cleverId,
      ];
      if (!keys.some((k) => selectedDistrict.schools.includes(k))) {
        setSelectedSchool(undefined);
      }
      // if (!selectedDistrict.schools.includes())
    }
    refresh();
  }, [selectedDistrict]);

  useEffect(() => {
    const resize = () => setOnMobile(window.innerWidth <= 600);
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);
  const closeButtonStyle = {
    position: 'absolute',
    top: '2vmin',
    right: '2vmin',
    fontSize: '2.5vmin',
    backgroundColor: 'white',
    color: '#FF0000',
    padding: '1vmin 2vmin',
    borderRadius: '1vmin',
    border: '0.3vmin solid #FF0000',
    cursor: 'pointer',
    transition: '0.3s',
    minWidth: '18vmin', // Increased width
    minHeight: '2vmin', // Ensure button height for touch devices
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    boxShadow: '0 0.5vmin 1vmin rgba(0,0,0,0.1)', // Subtle shadow for depth
    // zIndex: 3001, // Ensure it's above other elements
  };

  if(viewedDistrict){
    const modifiedUser = JSON.parse(JSON.stringify(user));
    modifiedUser.user.user = 'superadmin';
    modifiedUser.user.district = viewedDistrict;
    // may not need this check
    if(viewingCleverDistrict)
      modifiedUser.user.cleverId = "Needs Any Non Null Value";
    else
      modifiedUser.user.cleverId = null
    return(
      <div>
        <StudentData user={modifiedUser} anonData={true}></StudentData>
        <button style={closeButtonStyle} onClick={() => {setViewedDistrict(null); setViewingCleverDistrict(null)}}>Exit</button>
      </div>
    )
  }
  return (
    <div>
      {(!schoolData || settingAdmins) && (
        <LoadingOverlay></LoadingOverlay>
        // <div
        //   id="progress"
        //   style={{
        //     top: 0,
        //     left: 0,
        //     height: "100vh",
        //     width: "100vw",
        //     position: "fixed",
        //     background: "rgba(0,0,0,0.5)",
        //     zIndex: "100",
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <CircularProgress style={{ height: "60px", width: "60px" }} />
        // </div>
      )}
      <div
        className="studentscol"
        style={disableMargin ? { marginLeft: 0 } : {}}
      >
        {goBack && (
          <p
            onClick={goBack}
            id="GBB"
            style={{
              color: "#fff",
              marginBottom: 0,
              fontSize: "2vmin",
              padding: "1.5vmin",
              marginTop: "2vmin",
              marginLeft: "2vmin",
              borderRadius: "5px",
              alignSelf: "flex-start",
              backgroundColor: "#4cc68d",
            }}
          >
            Go Back
          </p>
        )}

        {user.user.user === "123wellness" && (
          <>
            <p className="SSV2Title">Select a district below</p>
            {!selectedDistrict && (
              <AddDistrictButton
                refresh={refresh}
                onMobile={onMobile}
                realmUser={realmUser}
              />
            )}
            <div className="SSV2ButtonContainer">
              {districtData &&
                (selectedDistrict ? [selectedDistrict] : districtData).map(
                  (district, i) => (
                    <DistrictButton
                      key={i}
                      data={district}
                      onMobile={onMobile}
                      selectedDistrict={selectedDistrict}
                      setSelectedDistrict={setSelectedDistrict}
                      setViewedDistrict={setViewedDistrict}
                      setViewingCleverDistrict={setViewingCleverDistrict}
                      user={user}
                    />
                  )
                )}
            </div>
            {selectedDistrict && !selectedDistrict.cleverId && (
              <>
                <div className="spreadsheetrow" style={{ alignSelf: "center" }}>
                  <div
                    className="limitspreadsheet"
                    style={{ backgroundColor: "whitesmoke" }}
                  >
                    <ReactDataSheet
                      dataEditor={(props) => <CustomEditor {...props} />}
                      data={superAdminGrid}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={(changes) => {
                        const grid = superAdminGrid.map((row) => [...row]);
                        changes.forEach(({ cell, row, col, value }) => {
                          grid[row][col] = { ...grid[row][col], value };
                        });
                        setSuperAdminGrid(grid);
                      }}
                    />
                  </div>
                </div>
                <p
                  onClick={async () => {
                    if (!settingAdmins) {
                      updateSettingAdmins(true);
                      const newSuperadmins = await gridToPeople();
                      //console.log(newSuperadmins);

                      await updateDistrictV2(selectedDistrict._id, realmUser, {
                        superadmins: newSuperadmins,
                      });
                      // create prevState variable, and run add or delete on diff
                      // we also need to save waht it looked like before and remove and privelages from people deletes
                      setOriginalSuperadmins(newSuperadmins);
                      updateSettingAdmins(false);
                    }
                  }}
                  style={{
                    margin: 0,
                    color: "#4cc68d",
                    marginTop: "2vmin",
                    borderRadius: "5px",
                    borderStyle: "solid",
                    borderColor: "#4cc68d",
                    padding: "1vmin",
                    borderWidth: "2.5px",
                    fontSize: "2vmin",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Save Changes
                </p>
              </>
            )}
          </>
        )}

        {((user.user.user !== "123wellness" && !selectedSchool) ||
          (user.user.user === "123wellness" &&
            selectedDistrict &&
            !selectedSchool)) && (
          <p className="SSV2Title">Select a school below to manage classes.</p>
        )}

        <ToastContainer
          toastStyle={{ backgroundColor: "#4cc68d", color: "#FFF" }}
        />

        {/* holds school buttons */}
        <div className="SSV2ButtonContainer">
          {/* {s.district} */}
          {(user.user.user !== "123wellness" ||
            (user.user.user === "123wellness" && selectedDistrict)) &&
            schoolData &&
            (selectedSchool
              ? // if school is selected, always show just that school
                schoolData.filter(
                  (s) =>
                    s.name === selectedSchool && s.district === selectedDistrict
                )
              : user.user.user === "123wellness"
              ? // if not, we need to filter schools in the current district
                // for 123 wellness viewers
                schoolData.filter((s) =>
                  [
                    selectedDistrict._id,
                    selectedDistrict.name,
                    selectedDistrict.cleverId,
                    selectedDistrict.district,
                  ].includes(s.district)
                )
              : schoolData
            ).map((school, i) => (
              <SchoolButton
                key={i}
                onMobile={onMobile}
                schoolData={school}
                selectedSchool={selectedSchool}
                setSelectedSchool={setSelectedSchool}
              />
            ))}
        </div>
        {((user.user.user === "123wellness" && selectedDistrict) ||
          user.user.user !== "123wellness") &&
        selectedSchool ? (
          <ClassesShowcase
            refresh={refresh}
            onMobile={onMobile}
            schoolData={schoolData}
            setSchoolData={setSchoolData}
            district={
              user.user.user === "123wellness"
                ? selectedDistrict.cleverId ?? selectedDistrict.name
                : user.user.district
            }
            cleverUser={
              user.user.user === "123wellness"
                ? !!selectedDistrict.cleverId
                : !!user.user.cleverId
            }
            userClasses={user.user.class}
            selectedSchool={selectedSchool}
            setSelectedSchool={setSelectedSchool}
            isAdmin={
              user.user.user === "admin" ||
              user.user.user === "superadmin" ||
              user.user.user === "123wellness"
            }
            user={user.user.user}
          />
        ) : user.user.user !== "superadmin" &&
          user.user.user !== "123wellness" ? (
          <></>
        ) : user.user.user === "123wellness" && selectedDistrict ? (
          <AddSchoolButton
            refresh={refresh}
            onMobile={onMobile}
            district={selectedDistrict.cleverId ?? selectedDistrict.name}
            highlightedColor={"#4cc68d"}
          />
        ) : user.user.user === "superadmin" ? (
          <AddSchoolButton
            refresh={refresh}
            onMobile={onMobile}
            district={user.user.district}
            highlightedColor={"#4cc68d"}
          />
        ) : (
          <></>
        )}
        <Spacer height="25vh" />
      </div>
    </div>
  );
}
